import {styled} from "@mui/material/styles";
import {AppBar, Link, Toolbar} from "@mui/material";
import {bgBlur} from "../../utils/cssStyles";

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 92;

const StyledHeader = styled(AppBar)(({theme}) => ({
  ...bgBlur({color: theme.palette.background.default}),
  boxShadow: 'none',
}));

const StyledToolbar = styled(Toolbar)(({theme}) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

const StyledTopNavLink = styled(Link)(({theme}) => ({
  '&.active': {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
  },
}));

export {
  StyledHeader,
  StyledToolbar,
  StyledTopNavLink
}