import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Radio,
  Stack,
  Typography,
} from '@mui/material';
import { matchIsValidTel, MuiTelInput } from 'mui-tel-input';
import { Controller } from 'react-hook-form';
import Iconify from '../iconify';
import { getClient, getClientAddress } from '../../services/expedition';
import { FormTextField } from '../forms';
import { getPlaceDetails } from '../../services/geocode';
import GoogleAutocomplete2 from '../google-autocomplete/GoogleAutocomplete2';
import Loader from '../loader/Loader';

const RequiredField = ({ message = 'Ce champ est obligatoire' }) => (
  <Typography variant="caption" display="block" color="error" gutterBottom>
    {message}
  </Typography>
);

const Correspondent = ({ title, icon, type, control, clientInfo, errors, setClientInfo, reset }) => {
  const [loading, setLoading] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [phone, setPhone] = useState('');

  useEffect(() => {
    if (clientInfo[type]?.phone) {
      setPhone(clientInfo[type]?.phone);
      getClientAddress(clientInfo[type].phone, type).then((gaddresses) => {
        setAddresses(gaddresses);
      });
    }
    return () => {};
  }, []);

  const handleChangePhone = (value) => {
    reset({ phone: value });
    setPhone(value);
    const phoneF = value.split(' ').join('');
    if (matchIsValidTel(value)) {
      setLoading(true);
      getClient(phoneF).then((client) => {
        console.log({ client });
        if (client) {
          reset({ ...client });
          setLoading(false);
        }
        setLoading(false);
      });
      getClientAddress(phoneF, type).then((gaddresses) => {
        setAddresses(gaddresses);
        setClientInfo((prevState) => ({
        ...prevState,
        newAddress: gaddresses.length <= 0,
      }));
      });
    }
  };

  return (
    <>
      <Card sx={{ my: 3 }}>
        <CardHeader
          disableTypography
          avatar={<Iconify icon={icon} width={30} />}
          title={
            <Link to="#" variant="subtitle2" color="primary" underline="none" sx={{ textTransform: 'uppercase' }}>
              {title}
            </Link>
          }
        />
        <CardContent>
          <Stack spacing={2} display="flex" alignContent="center" justifyContent="center">
            <Controller
              name="phone"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, ...field }, fieldState }) => (
                <MuiTelInput
                  {...field}
                  preferredCountries={['FR', 'BE', 'CM']}
                  langOfCountryName="fr"
                  defaultCountry="CM"
                  error={fieldState.invalid}
                  onChange={(value) => {
                    onChange(value);
                    handleChangePhone(value);
                  }}
                />
              )}
            />
            {errors.phone && <RequiredField message="Numéro invalide" />}
            {loading && <Loader />}
            {matchIsValidTel(phone) && !loading && (
              <>
                <FormTextField
                  name="name"
                  type="text"
                  control={control}
                  rules={{ required: true }}
                  label="Nom"
                />
                {errors.name && <RequiredField />}
                <FormTextField
                  name="surname"
                  type="text"
                  control={control}
                  label="Prénom"
                />
                {errors.surname && <RequiredField />}
                <FormTextField
                  name="email"
                  type="email"
                  control={control}
                  rules={{
                    required: false,
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: 'Entered value does not match email format',
                    },
                  }}
                  label="Email"
                />
                {errors.email && <RequiredField message="Valeur incorrecte" />}

                <Typography variant="button" display="block" gutterBottom>
                  Adresse {type === 'sender' ? ' de depart' : " d'arrivée"}
                </Typography>
                {!clientInfo?.newAddress && addresses.length > 0 && (
                  <>
                    <List>
                      {errors.promoting2 && 'errpor'}
                      {addresses.map((address, index) => (
                        <Box key={index}>
                          <ListItem
                            onClick={() => {
                              setClientInfo((prevState) => ({ ...prevState, address }));
                            }}
                            disablePadding
                            secondaryAction={
                              <IconButton edge="end" aria-label="address">
                                <Radio
                                  checked={clientInfo.address?.id === address?.id}
                                  onChange={(e, value) => {
                                    if (value) {
                                      setClientInfo((prevState) => ({ ...prevState, address }));
                                    } else {
                                      setClientInfo((prevState) => ({ ...prevState, address: {} }));
                                    }
                                  }}
                                  name="address-select"
                                />
                              </IconButton>
                            }
                          >
                            <ListItemButton>
                              <ListItemIcon>
                                <Iconify icon="carbon:location-filled" sx={{ color: 'text.secondary' }} />
                              </ListItemIcon>
                              <ListItemText primary={`${address.locality} ${address.country}`} />
                            </ListItemButton>
                          </ListItem>
                          <Divider />
                        </Box>
                      ))}
                    </List>
                    {!clientInfo.address && (
                      <Typography variant="caption" display="block" color="error" gutterBottom>
                        Choisissez une adresse
                      </Typography>
                    )}
                    <Button
                      onClick={() => {
                        setClientInfo((prevState) => ({
                          ...prevState,
                          newAddress: true,
                        }));
                      }}
                    >
                      Nouvelle adresse
                    </Button>
                  </>
                )}
                {clientInfo.newAddress && (
                  <>
                    <GoogleAutocomplete2
                      name="address"
                      label={`Adresse ${title.toLowerCase()}`}
                      control={control}
                      onSelectPlace={async (place) => {
                        const address = await getPlaceDetails(place, '');
                        setClientInfo((prevState) => ({ ...prevState, address: { ...address, type  } }));
                      }}
                      error={Boolean(errors.address)}
                    />
                    {errors.address && <RequiredField message="Entrer une adresse valide" />}
                    {addresses.length > 0 && (
                      <Button
                        onClick={() => {
                          setClientInfo((prevState) => ({
                            ...prevState,
                            newAddress: false,
                          }));
                        }}
                      >
                        Adresse récentes
                      </Button>
                    )}
                  </>
                )}
              </>
            )}
          </Stack>
        </CardContent>
      </Card>
    </>
  );
};

export default Correspondent;
