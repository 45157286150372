import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container, Grid,
  Link,
  Stack,
  Table,
  TableCell,
  TableRow,
  Typography
} from "@mui/material";

import { useParams } from 'react-router-dom';

import { getAllHistory, getColisInfo } from '../services/suivi';
import PageLayout from '../layouts/page/PageLayout';
import Iconify from '../components/iconify';
import HistoryTimeline from '../components/history-timeline/HistoryTimeline';
import { getStatus, getStatusPayment } from '../services/colis';

function SuiviColis() {
  const { colisId } = useParams();

  const [colisInfo, setcolisInfo] = useState({});
  const [histories, setHistories] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getColisInfo(colisId).then((value) => {
      setcolisInfo(value);
    });
    getAllHistory(colisId).then((value) => {
      setHistories(value);
      setLoading(false);
      console.log(colisId);
    });
    return () => {};
  }, []);

  return (
    <PageLayout title="Suivi ">
      <Container>
        <Stack direction="column" sx={{ my: 5 }} spacing={2}>
          <Typography variant="h4">Suivi Colis</Typography>
        </Stack>

        {loading && (
          <Box flexDirection="column" sx={{ display: 'flex', alignItems: 'center', width: 1 }}>
            <CircularProgress />
          </Box>
        )}
        {!loading && (
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <HistoryTimeline
                title="Paiement"
                list={histories
                  .filter((history) => history.type === 'payment')
                  .map((h, index) => ({
                    id: index,
                    title: `${h.placeAdress.country}, ${h.placeAdress.locality}`,
                    type: h.nameUser,
                    time: +h.createdAt,
                    status: getStatusPayment(h.paid),
                  }))}
              />
              <Box mt={3} />
              <HistoryTimeline
                title="Parcours"
                list={histories
                  .filter((history) => history.type === 'path')
                  .map((h, index) => ({
                    id: index,
                    title: `${h.placeAdress.country}, ${h.placeAdress.locality}`,
                    type: h.nameUser,
                    time: +h.createdAt,
                    status: getStatus(h.statut),
                  }))}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Card d sx={{ mb: 3, maxWidth: 650 }}>
                <CardHeader
                  disableTypography
                  avatar={<Iconify icon="vaadin:package" />}
                  title={
                    <Link to="#" variant="subtitle2" color="primary" underline="none">
                      Informations du colis
                    </Link>
                  }
                />
                <CardContent>
                  <Stack display="flex">
                    <Table sx={{ width: 1 }} aria-label="simple table">
                      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell component="th" scope="row">
                          Code
                        </TableCell>
                        <TableCell align="right">{colisInfo.code}</TableCell>
                      </TableRow>
                      {colisInfo.weight && (
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell component="th" scope="row">
                            Poids
                          </TableCell>
                          <TableCell align="right">{colisInfo.weight} kg</TableCell>
                        </TableRow>
                      )}
                      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell component="th" scope="row">
                          Prix
                        </TableCell>
                        <TableCell align="right">{`${colisInfo.price} ${colisInfo.currency}`}</TableCell>
                      </TableRow>
                      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell component="th" scope="row">
                          Destinataire
                        </TableCell>
                        <TableCell align="right">{colisInfo.sender.name}</TableCell>
                      </TableRow>
                    </Table>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )}
      </Container>
    </PageLayout>
  );
}

export default SuiviColis;
