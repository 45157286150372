import React, { useEffect, useState } from 'react';
import { Autocomplete, Box, Card, CardContent, CardHeader, Link, Stack, TextField, Typography } from '@mui/material';
import md5 from 'md5';
import { Controller } from 'react-hook-form';
import Iconify from '../iconify';
import { getTypesColis } from '../../services/paths';
import { FormTextField } from '../forms';

const RequiredField = ({ message = 'Ce champ est obligatoire' }) => (
  <Typography variant="caption" display="block" color="error" gutterBottom>
    {message}
  </Typography>
);

const optionLabel = [
  'Expédition en ligne',
  'Expédition en agence',
   'Expédition par la poste',
];

const ColisInfo = ({ icon, colisInfo, setColisInfo, errors, path, control }) => {
  const [typesColis, setTypesColis] = useState([]);
  const [showInputs, setShowInputs] = useState({
    kg: false,
    volume: false,
  });

  useEffect(() => {
    onSelectTypeColis(colisInfo.typeColis ?? {});
    getTypesColis(md5(path)).then((results) => {
      setTypesColis(results);
    });
    return () => {};
  }, [path]);

  useEffect(() => {
    if (colisInfo.typeColis) {
      const price = getPrice();
      setColisInfo((prevState) => ({ ...prevState, price, restPrice: price }));
    }
    return () => {};
  }, [colisInfo.weight, colisInfo.width, colisInfo.height, colisInfo.length, colisInfo.typeColis]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setColisInfo((prevState) => ({ ...prevState, [name]: value }));
  };

  const getPrice = () => {
    const showVolume = colisInfo.typeColis.priceVolume > 0;
    const showKg = colisInfo.typeColis.priceKg > 0;
    if (!showVolume && !showKg) {
      return colisInfo.typeColis.priceRef;
    }
    const priceperKg = Number(colisInfo.weight ?? 0) * Number(showKg ? colisInfo.typeColis.priceKg : 0);
    const priceperVol =
      Number(colisInfo.width ?? 0) *
      Number(colisInfo.height ?? 0) *
      Number(colisInfo.length ?? 0) *
      Number(showVolume ? colisInfo.typeColis.priceVolume : 0);
    const priceRef = Number(colisInfo.typeColis.priceRef);

    return Math.max(priceperKg, priceperVol, priceRef).toString();
  };

  const onSelectTypeColis = (typeColis) => {
    const showVolume = typeColis.priceVolume > 0;
    const showKg = typeColis.priceKg > 0;

    console.log(typeColis);

    setShowInputs({ kg: showKg, volume: showVolume });
    setColisInfo((prevState) => ({ ...prevState, typeColis: { ...typeColis }, currency: typeColis.currency }));
  };

  return (
    <Card sx={{ my: 3 }}>
      <CardHeader
        disableTypography
        avatar={<Iconify icon={icon} width={30} />}
        title={
          <Link to="#" variant="subtitle2" color="primary" underline="none" sx={{ textTransform: 'uppercase' }}>
            Colis
          </Link>
        }
      />
      <CardContent>
        <Stack spacing={2}>
          <Controller
            name="typeColis"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value, ...field } }) => (
              <Autocomplete
                onInputChange={(event, newValue, reason) => {
                  if (reason === 'clear') {
                    onSelectTypeColis({});
                  }
                }}
                value={value || ''}
                noOptionsText="Aucun type de colis"
                options={typesColis}
                onChange={(event, value) => {
                  onChange(value);
                  onSelectTypeColis(value);
                  return value;
                }}
                getOptionLabel={(option) => option.name ?? ''}
                renderInput={(params) => <TextField {...params} label="Type de colis" {...field} />}
              />
            )}
          />
          {Object.values(colisInfo.typeColis ?? {}).length <= 0 && (
            <RequiredField message="Selectionnez un type de colis" />
          )}

          {showInputs.kg && (
            <>
              <FormTextField
                label="Poids"
                type="number"
                name="weight"
                control={control}
                handleChange={handleChange}
                step={0.5}
              />
              {errors.weight && <RequiredField />}
            </>
          )}

          {showInputs.volume && (
            <>
              <>
                <FormTextField
                  label="Longueur"
                  type="number"
                  name="length"
                  control={control}
                  step={0.5}
                  handleChange={handleChange}
                  sx={{ width: 1 }}
                />
                {errors.length && <RequiredField />}
              </>
              <>
                <FormTextField
                  label="Largeur"
                  type="number"
                  name="width"
                  control={control}
                  handleChange={handleChange}
                  step={0.5}
                  sx={{ width: 1 }}
                />
                {errors.width && <RequiredField />}
              </>
              <>
                <FormTextField
                  label="Hauteur"
                  type="number"
                  name="height"
                  control={control}
                  handleChange={handleChange}
                  step={0.5}
                  sx={{ width: 1 }}
                />
                {errors.height && <RequiredField />}
              </>
            </>
          )}
          {colisInfo.price && (
            <Box flexDirection="column" sx={{ display: 'flex', alignItems: 'center', width: 1 }}>
              <Typography
                variant="button"
                display="block"
                color="success"
                textTransform="uppercase"
                gutterBottom
                textAlign="center"
              >
                {' '}
                Prix : {colisInfo.price} {colisInfo.typeColis.currency}{' '}
              </Typography>
            </Box>
          )}
          <FormTextField label="Valeur" name="value" control={control} handleChange={handleChange} type="text" />
          {errors.value && <RequiredField />}
          <FormTextField
            id="outlined-multiline-static"
            label="Description"
            control={control}
            multiline
            rows={4}
            handleChange={handleChange}
            name="description"
          />
          {errors.description && <RequiredField />}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default ColisInfo;
