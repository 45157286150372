import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// imports from material design
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Container,
  Grid,
  Link,
  Stack,
  Table,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';

// imports from other components in the project
import { getColisInfo } from '../services/suivi';
import { fDateTime } from '../utils/formatTime';
import PageLayout from '../layouts/page/PageLayout';
import Iconify from '../components/iconify';
import { getStatus, getStatusPayment } from '../services/colis';
import CorrespondentDialog from '../components/correspondent-dialog/CorrespondentDialog';
import { getCorrespondentAddress } from '../services/correspondents';

export function FicheColis() {
  const { colisId } = useParams();
  const [loading, setloading] = useState(true);
  const [colisInfo, setColisInfo] = useState([]);
  const [correspondentsAddress, setCorrespondentsAddress] = useState([]);
  const [selectedCorrespondent, setSelectedCorrespondent] = useState({});
  const [openDialog, setOpenDialog] = useState(false);

  const navigate = useNavigate();

  const loadData = (correspondent) => {
    setSelectedCorrespondent(correspondent);
    getCorrespondentAddress(correspondent.id).then((address) => {
      setCorrespondentsAddress(address);
      setOpenDialog(true);
    });
  };

  useEffect(() => {
    getColisInfo(colisId).then((value) => {
      setColisInfo(value);
      setloading(false);
    });
    return () => {};
  }, []);

  return (
    <PageLayout title="Fiche colis">
      <Container>
        <Stack direction="column" sx={{ my: 3 }} spacing={1}>
          <Typography variant="h4">Fiche Colis</Typography>
          <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end">
            <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
              {!(colisInfo.status === 2 || (colisInfo.status !== 1 && colisInfo.status !== 6)) && (
                <Button
                  variant="outlined"
                  size="small"
                  color="secondary"
                  startIcon={<Iconify icon="iconoir:page-edit" />}
                  onClick={() => navigate(`/update-colis/${colisInfo.id}`)}
                >
                  Modifier
                </Button>
              )}
              <Button
                variant="outlined"
                size="small"
                color="primary"
                startIcon={<Iconify icon="mdi:clipboard-text-history-outline" />}
                onClick={() => navigate(`/history-colis/${colisInfo.id}`)}
              >
                Suivi
              </Button>
            </Stack>
          </Stack>
        </Stack>

        {loading && (
          <Box flexDirection="column" sx={{ display: 'flex', alignItems: 'center', width: 1 }}>
            <CircularProgress />
          </Box>
        )}
        {!loading && (
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <CardBox title={`Informations du colis`} icon="vaadin:package">
                <Stack display="flex">
                  <Table sx={{ width: 1 }} aria-label="simple table">
                    <LineBox title="Code" value={colisInfo.code} />
                    <LineBox title="Poids" value={`${colisInfo.weight} kg`} />
                    <LineBox title="Prix" value={`${colisInfo.price} ${colisInfo.currency}`} />
                    <LineBox title="Type" value={colisInfo.typeColis.name} />
                    {colisInfo.weight && <LineBox title="Valeur" value={colisInfo.value} />}
                    <LineBox title="Description" value={colisInfo.description} />
                    <LineBox title="Date de création" value={fDateTime(+colisInfo.createdAt)} />
                  </Table>
                </Stack>
              </CardBox>
            </Grid>
            <Grid item xs={12} md={6}>
              <CardBox title="Status du colis" icon="pajamas:status-waiting" mt={3}>
                <Stack direction="row" spacing={2} p={1}>
                  <Chip
                    variant="outlined"
                    color={getStatus(colisInfo.status).color}
                    label={getStatus(colisInfo.status).name}
                  />
                  <Chip
                    variant="outlined"
                    color={getStatusPayment(colisInfo.paid).color}
                    label={getStatusPayment(colisInfo.paid).name}
                  />
                </Stack>
              </CardBox>
              <AddressBox
                address={colisInfo.addressStart}
                title="Adresse de départ"
                icon="fluent:location-arrow-right-48-regular"
              />
              <AddressBox
                address={colisInfo.addressArrival}
                title="Adresse d'arrivée"
                icon="fluent:location-arrow-left-48-regular"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Card d sx={{ my: 3, maxWidth: 650, cursor: 'pointer' }} onClick={() => loadData(colisInfo.sender)}>
                <CardHeader
                  disableTypography
                  avatar={<Iconify icon="fluent:people-28-regular" />}
                  title={
                    <Link to="#" variant="subtitle2" color="primary" underline="none">
                      Expéditeur
                    </Link>
                  }
                />
                <CardContent>
                  <Stack display="flex">
                    <Table sx={{ width: 1 }} aria-label="simple table">
                      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell component="th" scope="row">
                          Nom
                        </TableCell>
                        <TableCell align="right">{colisInfo.sender.name}</TableCell>
                      </TableRow>
                      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell component="th" scope="row">
                          Prénom
                        </TableCell>
                        <TableCell align="right">{colisInfo.sender.surname}</TableCell>
                      </TableRow>
                      {colisInfo.sender.email && (
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell component="th" scope="row">
                            E-mail
                          </TableCell>
                          <TableCell align="right">{colisInfo.sender.email} kg</TableCell>
                        </TableRow>
                      )}
                    </Table>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card d sx={{ my: 3, maxWidth: 650, cursor: 'pointer' }} onClick={() => loadData(colisInfo.recipient)}>
                <CardHeader
                  disableTypography
                  avatar={<Iconify icon="fluent:people-28-regular" />}
                  title={
                    <Link to="#" variant="subtitle2" color="primary" underline="none">
                      Destinataire
                    </Link>
                  }
                />
                <CardContent>
                  <Stack display="flex">
                    <Table sx={{ width: 1 }} aria-label="simple table">
                      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell component="th" scope="row">
                          Nom
                        </TableCell>
                        <TableCell align="right">{colisInfo.recipient.name}</TableCell>
                      </TableRow>
                      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell component="th" scope="row">
                          Prénom
                        </TableCell>
                        <TableCell align="right">{colisInfo.recipient.surname}</TableCell>
                      </TableRow>
                      {colisInfo.recipient.email && (
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell component="th" scope="row">
                            E-mail
                          </TableCell>
                          <TableCell align="right">{colisInfo.recipient.email} kg</TableCell>
                        </TableRow>
                      )}
                    </Table>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )}
      </Container>
      <CorrespondentDialog
        open={openDialog}
        correspondentsAddress={correspondentsAddress}
        onClose={() => setOpenDialog(false)}
        selectedCorrespondent={selectedCorrespondent}
      />
    </PageLayout>
  );
}

const CardBox = ({ title, icon, children, ...props }) => (
  <Card d sx={{ my: 3, maxWidth: 650, ...props }}>
    <CardHeader
      disableTypography
      avatar={<Iconify icon={icon} />}
      title={
        <Link to="#" variant="subtitle2" color="primary" underline="none">
          {title}
        </Link>
      }
    />
    <CardContent>{children}</CardContent>
  </Card>
);

const LineBox = ({ title, value }) => (
  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
    <TableCell component="th" scope="row">
      {title}
    </TableCell>
    <TableCell align="right">{value}</TableCell>
  </TableRow>
);

const AddressBox = ({ title, icon, address }) => (
  <CardBox title={title} icon={icon}>
    <Stack display="flex">
      <Table sx={{ width: 1 }} aria-label="simple table">
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell component="th" scope="row">
            {address.country}, {address.locality}
          </TableCell>
        </TableRow>
      </Table>
    </Stack>
  </CardBox>
);

const UserBox = ({ title, icon, correspondent }) => (
  <CardBox title={title} icon={icon}>
    <Stack display="flex">
      <Table sx={{ width: 1 }} aria-label="simple table">
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell component="th" scope="row">
            Nom
          </TableCell>
          <TableCell align="right">{correspondent.name}</TableCell>
        </TableRow>
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell component="th" scope="row">
            Prénom
          </TableCell>
          <TableCell align="right">{correspondent.surname}</TableCell>
        </TableRow>
        {correspondent.email && (
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              E-mail
            </TableCell>
            <TableCell align="right">{correspondent.email} kg</TableCell>
          </TableRow>
        )}
      </Table>
    </Stack>
  </CardBox>
);
