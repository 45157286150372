// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Paper } from '@mui/material';
// hooks
import React, { useEffect, useState } from 'react';
// components
// sections
import { useSearchParams } from 'react-router-dom';
import { LoginForm } from '../sections/auth/login';
import PageLayout from '../layouts/page/PageLayout';
import OtpForm from '../sections/auth/login/OtpForm';
import { getAndSaveCurrentPosition, loadMapScript } from "../services/geocode";
import { useFirebaseAuth } from '../contexts/FirebaseAuthContext';
import NewUser from '../components/new-user/NewUser';

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  marginTop: 100,
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const { user, userExist, setUserExist } = useFirebaseAuth();
  const [step, setStep] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();

  if (!userExist) {
    searchParams.set('type', 'new-user');
  }

  const loaded = React.useRef(false);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadMapScript()
    }

    loaded.current = true;

    getAndSaveCurrentPosition();
  }

  useEffect(
    () =>
      /* fetch('https://ipinfo.io/json?token=47cd373911d397')
        // .then((response) => response.json())
        .then((data) => console.log(data.json()))
        .catch((err) => console.error(err)); */
      () => {},
    []
  );

  return (
    <PageLayout title="Connexion">
      {searchParams.get('type') === 'new-user' ? <NewUser />: (
        <Container maxWidth="md">
          <StyledContent>
            <Typography variant="h4" gutterBottom textAlign="center">
              Connectez vous sur <Link variant="h4">TGV'AIRWABO</Link>
            </Typography>
            <Paper sx={{ p: 3, mt: 2 }}>
              {step === 0 && <LoginForm next={setStep} />}
              {step === 1 && <OtpForm />}
            </Paper>
          </StyledContent>
        </Container>
      )}
    </PageLayout>
  );
}


