import React from 'react';
import { TextField } from '@mui/material';
import { Controller } from 'react-hook-form';

const FormTextField = ({
  name,
  control,
  label,
  type,
  value,
  rules = { required: true },
  handleChange = function (e) {},
  ...others
}) => (
  <Controller
    control={control}
    name={name}
    defaultValue={value}
    rules={rules}
    render={({ field: { ref, onChange, value, ...fields } }) => (
      <TextField
        label={label}
        type={type}
        name={name}
        value={value || ''}
        inputRef={ref}
        {...fields}
        onChange={(e) => {
          onChange(e);
          handleChange(e);
        }}
        {...others}
      />
    )}
  />
);

export default FormTextField;
