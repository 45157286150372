import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Container,
  Grid,
  IconButton,
  Link,
  Slide,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import PageLayout from '../layouts/page/PageLayout';
import Iconify from '../components/iconify';
import { fDate, fTimestamp } from '../utils/formatTime';
import { getAgencies, getDepartures } from '../services/departures';
import EmptyBox from '../components/empty-box';
import { FormAutocomplete, FormDatePicker, FormTextField } from '../components/forms';
import RequiredField from '../components/forms/RequiredField';
import Loader from '../components/loader/Loader';

// ----------------------------------------------------------------------

export default function DeparturesPage() {
  const [openSearch, setOpenSearch] = useState(false);
  const [departures, setDepartures] = useState([]);
  const [allDepartures, setAllDepartures] = useState([]);
  const [agencies, setAgencies] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors, isValid },
  } = useForm({
    reValidateMode: 'onBlur',
  });

  useEffect(() => {
    getAgencies().then((agencies) => setAgencies(agencies));
    getDepartures().then((departures) => {
      setDepartures(departures);
      setAllDepartures(departures);
      setLoading(false);
    });
    return () => {};
  }, []);

  const onSubmitForm = (values) => {
    console.log(values);
    const startDate = fTimestamp(values.startDate);
    const newDepartures = allDepartures.filter(
      (departure) =>
        +departure.date >= startDate &&
        departure.path?.startAddress.country === values.startCountry.address.country &&
        departure.path?.arrivalAddress.country === values.arrivalCountry.address.country
    );

    setDepartures(newDepartures);
  };

  return (
    <PageLayout title="Nos départs">
      <Container>
        <Stack direction="column" sx={{ my: 5 }} spacing={2}>
          <Typography variant="h4">Nos prochains départs</Typography>
          {!openSearch && (
            <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end">
              <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
                <Button
                  variant="contained"
                  startIcon={<Iconify icon="eva:search-fill" />}
                  onClick={() => setOpenSearch(true)}
                >
                  Rechercher un départ
                </Button>
              </Stack>
            </Stack>
          )}
        </Stack>

        <Slide direction="up" in={openSearch} mountOnEnter unmountOnExit>
          <Card sx={{ mb: 3 }}>
            <CardHeader
              disableTypography
              avatar={<Iconify icon="eva:search-fill" />}
              title={
                <Link to="#" variant="subtitle2" color="primary" underline="none">
                  Rechercher
                </Link>
              }
              action={
                <IconButton
                  color="secondary"
                  onClick={() => {
                    setDepartures(allDepartures);
                    setOpenSearch(false);
                  }}
                >
                  <Iconify icon="eva:close-outline" />
                </IconButton>
              }
            />
            <CardContent>
              <form onSubmit={handleSubmit(onSubmitForm)}>
                <Stack spacing={2}>
                  <FormAutocomplete
                    getOptionLabel={(option) => option.address.country}
                    name="startCountry"
                    label="Pays de départ"
                    control={control}
                    options={agencies}
                    error={errors.startCountry}
                  />
                  {errors.startCountry && <RequiredField />}
                  <Box flexDirection="column" sx={{ display: 'flex', alignItems: 'center', width: 1 }}>
                    <IconButton
                      color="secondary"
                      size="medium"
                      onClick={() => {
                        const temp = getValues('startCountry');
                        console.log(temp);
                        console.log(getValues('arrivalCountry'));
                        setValue('startCountry', getValues('arrivalCountry'));
                        setValue('arrivalCountry', temp);
                      }}
                    >
                      <Iconify icon="fluent-mdl2:switcher-start-end" />
                    </IconButton>
                  </Box>
                  <FormAutocomplete
                    getOptionLabel={(option) => option.address.country}
                    name="arrivalCountry"
                    label="Pays d'arrivé"
                    control={control}
                    options={agencies}
                    error={errors.arrivalCountry}
                  />
                  {errors.arrivalCountry && <RequiredField />}
                  <FormDatePicker name="startDate" label="À partir du" control={control} error={errors.startDate} />
                  {errors.startDate && <RequiredField />}
                  <FormTextField
                    name="minWeigth"
                    label="Poids Minimum"
                    type="number"
                    control={control}
                    rules={{ required: false }}
                  />

                  <Button variant="contained" type="submit" startIcon={<Iconify icon="eva:search-fill" />}>
                    Rechercher
                  </Button>
                </Stack>
              </form>
            </CardContent>
          </Card>
        </Slide>

        <Grid container spacing={3}>
          {loading && <Loader />}
          {departures.length === 0 && !loading && <EmptyBox title="Aucun départ" />}
          {departures.map((departure) => (
            <Grid item xs={12} sm={6} md={4} key={departure.id}>
              <Card sx={{ '&:hover': { border: 1, borderColor: 'primary.light', cursor: 'pointer' } }}>
                <CardHeader
                  disableTypography
                  avatar={<Iconify icon="emojione-monotone:airplane-departure" width={25} height={25} />}
                  title={
                    <Link to="#" variant="subtitle2" color="primary" underline="none">
                      {fDate(+departure.date)}
                    </Link>
                  }
                />
                <CardContent>
                  <Stack direction="row" spacing={2}>
                    <Typography variant="body2" color="text.primary" textAlign="justify">
                      {departure.path.startAddress.country}
                    </Typography>
                    <Iconify icon="gg:airplane" />
                    <Typography variant="body2" color="text.primary" textAlign="justify">
                      {departure.path.arrivalAddress.country}
                    </Typography>
                  </Stack>
                  <Chip
                    variant="outlined"
                    color="secondary"
                    label={`${departure.avalaibleKg} Kg disponible`}
                    icon={<Iconify icon="game-icons:weight" />}
                    sx={{ mt: 2 }}
                  />
                </CardContent>
                <CardActions sx={{ px: 2 }}>
                  <Box sx={{ flexGrow: 1 }} />
                  <Button
                    color="primary"
                    size="small"
                    onClick={() => {
                      localStorage.setItem('path', JSON.stringify(departure.path));
                      console.log(departure);
                      navigate('/expedier-colis');
                    }}
                    endIcon={<Iconify icon="bi:send-check" />}
                  >
                    Expédition
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </PageLayout>
  );
}
