// @mui
import {
  Avatar,
  Box,
  Button,
  Container,
  Paper,
  Slide,
  Stack,
  Step,
  StepConnector,
  stepConnectorClasses,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import PageLayout from '../layouts/page/PageLayout';
import Iconify from '../components/iconify';

import Correspondent from '../components/correspondents/Correspondent';
import ColisInfo from '../components/colis-info/ColisInfo';
import { useFirebaseAuth } from '../contexts/FirebaseAuthContext';
import { fTimestamp } from '../utils/formatTime';
import { getAndSaveCurrentPosition, getSavedPosition } from "../services/geocode";
import Loader from '../components/loader/Loader';
import { getColis, sendColis, updateColis } from '../services/expedition';

// ----------------------------------------------------------------------

const steps = [
  { label: 'Expediteur', icon: 'fluent:people-28-regular' },
  {
    label: 'Destinataire',
    icon: 'fluent:people-28-regular',
  },
  { label: 'Colis', icon: 'vaadin:package' },
];

const GOOGLE_MAPS_API_KEY = 'AIzaSyAnyNCALPCrYfC0w7PET09AhpVXYxLwy5g';

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);

  getAndSaveCurrentPosition();
}

const CustomConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    zIndex: -1,
    top: 20,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.secondary.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

export default function SendColisPage() {
  const { user } = useFirebaseAuth();
  const navigate = useNavigate();
  const { colisId } = useParams();
  const [key, setKey] = useState('hello');

  const [senderInfo, setSenderInfo] = useState({});
  const [recipientInfo, setRecipientInfo] = useState({});
  const [colisInfo, setColisInfo] = useState({});
  const [colis, setColis] = useState({});

  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(false);

  const loaded = React.useRef(false);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector('head'),
        'google-maps'
      );
    }

    loaded.current = true;

    getAndSaveCurrentPosition();
  }

  const {
    control: controlSender,
    handleSubmit: handleSubmitSender,
    reset: resetSender,
    formState: { errors: errorsSender, isValid: isValidSender },
  } = useForm({
    reValidateMode: 'onBlur',
  });

  const {
    control: controlRecipient,
    handleSubmit: handleSubmitRecipient,
    reset: resetRecipient,
    formState: { errors: errorsRecipient, isValid: isValidRecipient },
  } = useForm({
    reValidateMode: 'onBlur',
  });

  const {
    control: controlColis,
    handleSubmit: handleSubmitColis,
    getValues: getValuesColis,
    reset: resetColis,
    formState: { errors: errorsColis, isValid: isValidColis },
  } = useForm({
    reValidateMode: 'onBlur',
  });


  useEffect(() => {
    if (colisId) {
      setInitialLoading(true);
      getColis(colisId).then((colis) => {
        if (colis.status === 2 || (colis.status !== 1 && colis.status !== 6)) {
          navigate('/');
        }
        const { addressArrival, addressStart, paid, userId, sender, recipient, status, ...colisInfo } = colis;
        resetSender({ ...sender });
        resetRecipient({ ...recipient });
        resetColis({ ...colisInfo });
        setSenderInfo({
          newAddress: false,
          address: addressStart,
          sender: { ...sender },
        });
        setRecipientInfo({ newAddress: false, address: addressArrival, receiver: { ...recipient } });
        setColisInfo({ ...colisInfo });
        setInitialLoading(false);
        console.log({ colisInfo });
      });
    }
    return () => {};
  }, []);

  const onSubmitSender = (values) => {
    setSenderInfo((prevStave) => ({ ...prevStave, sender: { ...prevStave.sender, ...values } }));
    console.log(senderInfo);
  };

  const onSubmitRecipient = (values) => {
    setRecipientInfo((prevStave) => ({ ...prevStave, receiver: { ...prevStave.receiver, ...values } }));
    console.log(values);
  };

  const onSubmitColis = (values) => {
    setColisInfo((prevStave) => ({ ...prevStave, ...values }));
    console.log(values);
  };

  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});

  const totalSteps = () => steps.length;

  const completedSteps = () => Object.keys(completed).length;

  const isLastStep = () => activeStep === totalSteps() - 1;

  const allStepsCompleted = () => completedSteps() === totalSteps();

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const checkSenderForm = () => {
    handleSubmitSender(onSubmitSender)();
    return isValidSender && Object.values(senderInfo.address ?? {}).length > 0;
  };

  const checkRecipientForm = () => {
    handleSubmitRecipient(onSubmitRecipient)();
    return isValidRecipient && Object.values(recipientInfo.address ?? {}).length > 0;
  };

  const checkColisForm = () => {
    handleSubmitColis(onSubmitColis)();
    return isValidColis;
  };

  const go = () => {
    const steps = [checkSenderForm(), checkRecipientForm(), checkColisForm()];
    return steps[activeStep];
  };

  const handleComplete = async () => {
    if (loading) return;

    if (go()) {
      const newCompleted = completed;
      newCompleted[activeStep] = true;
      setCompleted(newCompleted);
      handleNext();
      if (isLastStep()) {
        setLoading(true);
        const newcolis = {
          addressArrival: { ...recipientInfo.address },
          addressStart: { ...senderInfo.address },
          createdAt: fTimestamp().toString(),
          paid: 1,
          userId: user.id,
          sender: { createdBy: user, createdAt: fTimestamp().toString(), ...senderInfo.sender },
          recipient: { createdBy: user, createdAt: fTimestamp().toString(), ...recipientInfo.receiver },
          status: 1,
          ...colisInfo,
        };
        const path = localStorage.getItem('path');
        if (path) {
          newcolis.path = JSON.parse(path);
          localStorage.removeItem('path');
        }

        if (colisId) {
          const record = {
            userId: user.id,
            restPrice: colisInfo.restPrice,
            nameUser: `${user.name} ${user.surname}`,
            placeAdress: getSavedPosition(),
            paid: 1,
            statut: 7,
            type: 'path',
            createdAt: fTimestamp().toString(),
          };

          try {
            const result = await updateColis(newcolis, [record]);
            const col = await getColis(result.data);
            setColis(col);
            setLoading(false);
          } catch (error) {
            alert('Nous avons rencontré une erreur');
            console.log(error);
          }
        } else {
          const record = {
            userId: user.id,
            restPrice: colisInfo.restPrice,
            nameUser: `${user.name} ${user.surname}`,
            placeAdress: getSavedPosition(),
            paid: 1,
            statut: 1,
            type: 'path',
            createdAt: fTimestamp().toString(),
          };

          const record2 = { ...record, type: 'payment' };

          console.log({ newcolis, record });
          try {
            const result = await sendColis(newcolis, [record, record2]);
            const col = await getColis(result.data);
            setColis(col);
            setLoading(false);
          } catch (error) {
            alert('Nous avons rencontré une erreur');
            console.log(error);
          }
        }
      }
    }
  };

  const handleReset = () => {
    resetSender({ ...{} });
    resetRecipient({ ...{} });
    resetColis({ ...{} });
    setColisInfo({});
    setSenderInfo({});
    setRecipientInfo({});
    setActiveStep(0);
    setCompleted({});
    navigate('/expedier-colis');
  };

  const getText = () => (colisId ? 'Modifier' : 'Expédier');
  const getIcon = () => (colisId ? 'mdi:clipboard-edit-outline' : 'bi:send-check');

  const QontoStepIcon = (props) => {
    const { active, completed, className, icon } = props;

    return (
      <Box className={className}>
        <Avatar sx={{ ...(active && { bgcolor: 'secondary.main' }), ...(completed && { bgcolor: 'primary.main' }) }}>
          <Iconify icon={icon} />
        </Avatar>
      </Box>
    );
  };

  return (
    <PageLayout title="Expedier un colis">
      <Container>
        <Stack direction="row" sx={{ my: 5 }}>
          <Typography variant="h4">{colisId ? 'Modifier une expédition' : 'Nouvelle expedition'}</Typography>
          <Box sx={{ flexGrow: 1 }} />
        </Stack>
        {!initialLoading && (
          <Box sx={{ width: '100%' }}>
            <Stepper
              nonLinear
              activeStep={activeStep}
              alternativeLabel
              orientation="horizontal"
              connector={<CustomConnector />}
            >
              {steps.map(({ label, icon }, index) => (
                <Step key={label} completed={completed[index]} active={activeStep === index}>
                  <StepLabel StepIconComponent={QontoStepIcon} icon={icon}>
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            <div>
              {allStepsCompleted() ? (
                <>
                  <Paper flexDirection="column" sx={{ display: 'flex', alignItems: 'center', width: 1, mt: 4, p: 4 }}>
                    {loading && <Loader />}
                    {!loading && (
                      <Stack direction="column" display="flex" alignContent="center" justifyContent="center" width={1}>
                        <Typography variant="body" display="block" gutterBottom textAlign="center">
                          {colisId ? 'Modification reussie' : 'Expédition reussie'}, conserver votre code de colis
                        </Typography>
                        <Typography
                          variant="button"
                          display="block"
                          color="primary"
                          textTransform="uppercase"
                          gutterBottom
                          textAlign="center"
                        >
                          {colis.code ?? 'No code'}
                        </Typography>
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={() => {
                            console.log(colis.id);
                            navigate(`/fiche-colis/${colis.id}`);
                          }}
                        >
                          Voir mon colis
                        </Button>
                        {/* <Typography variant="body" display="block" gutterBottom textAlign="center">
                        Contactez ce numéro pour le suivi du colis
                      </Typography>
                      <Button
                        color="secondary"
                        onClick={() => {
                          window.open(
                            `https://wa.me/33780970003?text=Bonjour, je veux suivre le colis : ${colis.code}`,
                            '_blank'
                          );
                        }}
                      >
                        +33 7 80 97 00 03
                      </Button> */}
                      </Stack>
                    )}
                  </Paper>
                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Box sx={{ flex: '1 1 auto' }} />
                    {!loading && (
                      <Button onClick={handleReset} variant="outlined">
                        Nouveau colis
                      </Button>
                    )}
                  </Box>
                </>
              ) : (
                <>
                  <Slide direction="left" in={activeStep === 0} mountOnEnter unmountOnExit>
                    <form onSubmit={handleSubmitSender(onSubmitSender)} key={key}>
                      <Correspondent
                        title="Expéditeur"
                        icon={steps[0].icon}
                        type="sender"
                        reset={resetSender}
                        control={controlSender}
                        errors={errorsSender}
                        clientInfo={senderInfo}
                        setClientInfo={setSenderInfo}
                      />
                    </form>
                  </Slide>
                  <Slide direction="up" in={activeStep === 1} mountOnEnter unmountOnExit>
                    <form onSubmit={handleSubmitRecipient(onSubmitRecipient)}>
                      <Correspondent
                        title="Destinataire"
                        icon={steps[1].icon}
                        type="receiver"
                        reset={resetRecipient}
                        control={controlRecipient}
                        errors={errorsRecipient}
                        clientInfo={recipientInfo}
                        setClientInfo={setRecipientInfo}
                      />
                    </form>
                  </Slide>
                  <Slide direction="up" in={activeStep === 2} mountOnEnter unmountOnExit>
                    <form onSubmit={handleSubmitColis(onSubmitColis)}>
                      {loading ? (
                        <Loader />
                      ) : (
                        <ColisInfo
                          icon={steps[2].icon}
                          colisInfo={colisInfo}
                          setColisInfo={setColisInfo}
                          errors={errorsColis}
                          path={`${senderInfo.address?.country_code ?? ''}_${
                            recipientInfo.address?.country_code ?? ''
                          }`}
                          control={controlColis}
                          getValues={getValuesColis}
                        />
                      )}
                    </form>
                  </Slide>

                  {!loading && (
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, mb: 4 }}>
                      {activeStep !== 0 && (
                        <Button color="secondary" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                          Back
                        </Button>
                      )}
                      <Box sx={{ flex: '1 1 auto' }} />
                      <Button
                        variant="contained"
                        onClick={handleComplete}
                        sx={{ mr: 1 }}
                        endIcon={
                          <Iconify
                            icon={completedSteps() === totalSteps() - 1 ? getIcon() : 'ic:round-arrow-right-alt'}
                          />
                        }
                      >
                        {completedSteps() === totalSteps() - 1 ? getText() : 'Next'}
                      </Button>
                    </Box>
                  )}
                </>
              )}
            </div>
          </Box>
        )}
      </Container>
    </PageLayout>
  );
}
