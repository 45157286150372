// @mui

import React, { forwardRef, useEffect, useState } from 'react';
import { SnackbarProvider } from 'notistack';
import {
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Slide,
  Typography,
} from '@mui/material';
import HelmetTitle from '../components/helmet-title/HelmetTitle';
import Loader from '../components/loader/Loader';
import EmptyBox from '../components/empty-box';
import Iconify from '../components/iconify';
import { getCorrespondentAddress, getCorrespondents } from '../services/correspondents';
import CorrespondentDialog from '../components/correspondent-dialog/CorrespondentDialog';
import CorrespondentCard from "../components/correspondent-card/CorrespondentCard";

// ----------------------------------------------------------------------

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function CorrenspondentsPage() {
  const [correspondents, setCorrespondents] = useState([]);
  const [allCorrespondents, setAllCorrespondents] = useState([]);
  const [correspondentsAddress, setCorrespondentsAddress] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCorrespondent, setSelectedCorrespondent] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    setLoading(true);
    getCorrespondents().then((correspondents) => {
      setCorrespondents(correspondents);
      setAllCorrespondents(correspondents);

      setLoading(false);
    });
    return () => {};
  }, []);

  const loadData = (correspondent) => {
    setLoading(true);
    setSelectedCorrespondent(correspondent);
    getCorrespondentAddress(correspondent.id).then((address) => {
      setCorrespondentsAddress(address);
      setLoading(false);
      setOpenDialog(true);
    });
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchText(value);

    const newCorrespondent = correspondents.filter((correspondent) =>
      `${correspondent.name}${correspondent.surname}${correspondent.email}${correspondent.phone}`
        .toLowerCase()
        .includes(value.toLowerCase())
    );

    setCorrespondents(value ? newCorrespondent : allCorrespondents);
  };

  const handleCloseSearch = () => {
    setSearchText('');
    console.log(allCorrespondents);
    setCorrespondents(allCorrespondents);
  };

  return (
    <SnackbarProvider maxSnack={5}>
      <HelmetTitle title="Correspondants" />

      <>
        <Typography variant="h4" gutterBottom textAlign="center" sx={{ display: { xs: 'none' } }}>
          Votre profil
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="search">Rechercher un correspondant</InputLabel>
              <OutlinedInput
                id="search"
                type="text"
                value={searchText}
                onChange={handleSearch}
                startAdornment={
                  <InputAdornment position="start">
                    <IconButton aria-label="toggle password visibility" edge="end">
                      <Iconify icon="ic:baseline-search" />
                    </IconButton>
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility" onClick={handleCloseSearch} edge="end">
                      <Iconify icon="ion:close-outline" />
                    </IconButton>
                  </InputAdornment>
                }
                label="Rechercher un correspondant"
              />
            </FormControl>
          </Grid>

          {loading && <Loader />}
          {correspondents.length === 0 && !loading && <EmptyBox title="Aucun correspondant" />}
          {correspondents &&
            correspondents.map((correspondent) => (
              <Grid item xs={12} sm={6} md={6} key={correspondent.id} onClick={() => loadData(correspondent)}>
                <CorrespondentCard correspondent={correspondent} onClick={() => loadData(correspondent)} />
              </Grid>
            ))}
        </Grid>
      </>
      <CorrespondentDialog
        open={openDialog}
        correspondentsAddress={correspondentsAddress}
        onClose={() => setOpenDialog(false)}
        selectedCorrespondent={selectedCorrespondent}
      />
    </SnackbarProvider>
  );
}
