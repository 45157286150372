import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

// SETUP COLORS
const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
};

const PRIMARY = {
  50: '#FEFCFD',
  bg: "#FFFCFD",
  lighter: '#fcf4f4',
  light: '#f9e7e7',
  main: '#9c3b39',
  dark: '#863634',
  darker: '#6d312f',
  contrastText: '#fff',
};

const SECONDARY = {
  lighter: '#fbf7eb',
  light: '#f6eecb',
  main: '#d9a731',
  dark: '#ca9328',
  darker: '#ae7220',
  contrastText: '#fff',
};

const INFO = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#04297A',
  contrastText: '#fff',
};

const SUCCESS = {
  lighter: '#E9FCD4',
  light: '#AAF27F',
  main: '#54D62C',
  dark: '#229A16',
  darker: '#08660D',
  contrastText: GREY[800],
};

const WARNING = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#FFC107',
  dark: '#B78103',
  darker: '#7A4F01',
  contrastText: GREY[800],
};

const ERROR = {
  lighter: '#FFE7D9',
  light: '#FFA48D',
  main: '#FF4842',
  dark: '#B72136',
  darker: '#7A0C2E',
  contrastText: '#fff',
};

const palette = {
  common: { black: '#000', white: '#fff' },
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  divider: alpha(GREY[500], 0.24),
  text: {
    primary: GREY[800],
    secondary: GREY[600],
    disabled: GREY[500],
  },
  background: {
    paper: '#fff',
    default: PRIMARY[50],
    neutral: GREY[200],
  },
  action: {
    active: GREY[600],
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
  paid1: {
    main: '#FA0B0B',
    contrastText: '#fff',
  },
  paid2: {
    main: '#3C934F',
    contrastText: '#fff',
  },
  paid3: {
    main: '#0D4375',
    contrastText: '#fff',
  },
  paid4: {
    main: '#E99B26',
    contrastText: '#fff',
  },
  status0: {
    main: '#FA0B0B',
    contrastText: '#fff',
  },
  status1: {
    main: '#0478FF',
    contrastText: '#fff',
  },
  status2: {
    main: '#3C934F',
    contrastText: '#fff',
  },
  status3: {
    main: '#D9A731',
    contrastText: '#fff',
  },
  status4: {
    main: '#100BFA',
    contrastText: '#fff',
  },
  status5: {
    main: '#212B36',
    contrastText: '#fff',
  },
  status6: {
    main: '#0478FF',
    contrastText: '#fff',
  },
  status7: {
    main: '#ff9ea2',
    contrastText: '#fff',
  },
  status8: {
    main: '#ff9ea2',
    contrastText: '#fff',
  }
};

export default palette;
