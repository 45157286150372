import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase';

const getPaths = async () => {
  const ref = query(collection(db, 'enterprises', 'tgvairwabo', 'paths'));
  const querySnapshot = await getDocs(ref);
  const paths = querySnapshot.docs.map((doc) => doc.data());
  return paths;
};

const getTypesColis = async (pathId) => {
  const ref = collection(db, 'enterprises', 'tgvairwabo', 'types_colis');
  const q = query(ref, where('pathId', '==', pathId));

  const querySnapshot = await getDocs(q);
  const typesColis = querySnapshot.docs.map((doc) => doc.data());
  return typesColis;
};


const getAllTypesColis = async () => {
  const ref = collection(db, 'enterprises', 'tgvairwabo', 'types_colis');
  const q = query(ref);

  const querySnapshot = await getDocs(q);
  const typesColis = querySnapshot.docs.map((doc) => doc.data());
  return typesColis;
};

const getAsignPath = async () => {
  const typeColisPathPromise = [await getAllTypesColis(), await getPaths()]
  const [alltypeColis, allPaths] = await Promise.allSettled(typeColisPathPromise)

  const getIdtypeColis = alltypeColis.value.map(typeColis => typeColis.pathId)
  const paths = allPaths.value.filter((path) => getIdtypeColis.includes(path.id))

  return paths
}

export { getPaths, getTypesColis, getAllTypesColis, getAsignPath };
