import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';

const FormAutoComplete = ({ name, control, label, rules = { required: true }, options, getOptionLabel, ...others }) => (
  <Controller
    control={control}
    name={name}
    rules={rules}
    render={({ field: { ref, value, onChange, ...field } }) => (
      <Autocomplete
        options={options}
        getOptionLabel={getOptionLabel}
        onChange={(_, value) => onChange(value)}
        name={name}
        value={typeof value === 'string' ? options.find(o => o.id === value) : (value || null)}
        renderInput={(params) => (
          <TextField {...params} {...field} value={value || ''} fullWidth inputRef={ref} label={label} {...others} />
        )}
        isOptionEqualToValue={(option, value) => option.id === value.id}
      />
    )}
  />
);

export default FormAutoComplete;
