import React, { useState } from 'react';
import { Card, CardContent, CardHeader, Container, Link, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FormTextField } from '../forms';
import RequiredField from '../forms/RequiredField';
import GoogleAutocomplete2 from '../google-autocomplete/GoogleAutocomplete2';
import { getPlaceDetails } from '../../services/geocode';
import Iconify from '../iconify';
import { refreshUser, saveUser } from "../../services/login";
import { useFirebaseAuth } from '../../contexts/FirebaseAuthContext';

const NewUser = () => {
  const { user, userExist, setUserExist } = useFirebaseAuth();

  const [address, setAddress] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  const onSubmitForm = async (e) => {
    setLoading(true);
    if (address) {
      const data = { ...e, address };
      try {
        await saveUser(data);
        await refreshUser()
        setUserExist(true)
        setLoading(false);
        navigate(searchParams.get('redirect') ?? '/');
      } catch (e) {
        console.log({ e });
        setLoading(false);
        alert(JSON.stringify(e));
      }
    } else {
      alert('Adresse invalid');
    }
  };

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    reValidateMode: 'onBlur',
  });

  return (
    <Container sx={{ my: 10 }}>
      <Typography variant="h4" gutterBottom textAlign="center" sx={{ mt: 5 }}>
        Inscrivez vous sur <Link variant="h4">TGV'AIRWABO</Link>
      </Typography>
      <Card
        sx={{
          mb: 3,
          maxWidth: 'md',
          margin: 'auto',
          display: 'flex',
          marginTop: 5,
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <CardHeader disableTypography />
        <CardContent>
          <form onSubmit={handleSubmit(onSubmitForm)}>
            <Stack spacing={2}>
              <FormTextField
                name="name"
                label="Votre nom"
                type="text"
                control={control}
                rules={{ required: true }}
                error={Boolean(errors.name)}
              />
              {errors.name && <RequiredField />}
              <FormTextField
                name="surname"
                label="Votre prénom"
                type="text"
                control={control}
                error={Boolean(errors.surname)}
                rules={{ required: true }}
              />
              {errors.surname && <RequiredField />}
              <FormTextField
                name="email"
                label="Votre e-mail"
                type="email"
                control={control}
                error={Boolean(errors.email)}
                rules={{
                  required: true,
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: 'Email invalide',
                  },
                }}
              />
              {errors.email && <RequiredField />}
              <GoogleAutocomplete2
                name="address"
                label="Votre adresse"
                control={control}
                onSelectPlace={async (place) => {
                  const address = await getPlaceDetails(place, '');
                  setAddress(address);
                }}
                error={Boolean(errors.address)}
              />
              {errors.address && <RequiredField />}
              <LoadingButton
                loading={loading}
                variant="contained"
                type="submit"
                startIcon={<Iconify icon="majesticons:login-half-circle-line" />}
              >
                Inscription
              </LoadingButton>
            </Stack>
          </form>
        </CardContent>
      </Card>
    </Container>
  );
};

export default NewUser;
