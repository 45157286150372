import React from "react";
import { Helmet } from "react-helmet-async";

const HelmetTitle = ({ title }) => (
    <Helmet>
      <title>{`${title} | TGV'AIRWABO`}</title>
    </Helmet>
  );

export default HelmetTitle;
