import React, { useState } from 'react';
import {
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material';

import { getAllHistory, getColisInfoByCode } from '../services/suivi';
import PageLayout from '../layouts/page/PageLayout';
import Iconify from '../components/iconify';
import { getStatus } from '../services/colis';
import HistoryTimeline from '../components/history-timeline/HistoryTimeline';
import Loader from '../components/loader/Loader';

const SuiviColisSearchPage = () => {
  const [colisInfo, setcolisInfo] = useState({});
  const [histories, setHistories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState('');
  const [error, setError] = useState(false);

  const getColis = (code) => {
    setLoading(true);
    setError(false)
    getColisInfoByCode(code).then((value) => {
      if (!value) {
        setLoading(false);
        setError(true);
        return;
      }
      setcolisInfo(value);
      getAllHistory(value.id).then((value) => {
        setHistories(value);
        setError(false);
        setLoading(false);
      });
    });
  };

  const handleSuivi = (value) => {
    setCode(value);
    setError(false)
    setHistories([])
    getColis(value);
  };

  return (
    <PageLayout title="Suivi">
      <Container>
        <Stack direction="column" sx={{ my: 5 }} spacing={2}>
          <Typography variant="h4">Suivi Colis</Typography>
        </Stack>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} mb={3}>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="search">Entrer le code du colis</InputLabel>
              <OutlinedInput
                id="search"
                type="text"
                value={code}
                onChange={(e) => handleSuivi(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <IconButton aria-label="toggle password visibility" edge="end">
                      <Iconify icon="mdi:clipboard-text-history-outline" />
                    </IconButton>
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        setCode('');
                        setError(false)
                        setHistories([]);
                      }}
                      edge="end"
                    >
                      <Iconify icon="ion:close-outline" />
                    </IconButton>
                  </InputAdornment>
                }
                label="Entrer le code du colis"
              />
            </FormControl>
          </Grid>
          {loading && <Loader />}
        </Grid>
        {error && <Typography variant="h5" textAlign="center">Aucun colis trouvé</Typography>}
        {histories.length > 0 && (
          <HistoryTimeline
            title={`Parcours du colis ${code.toUpperCase()}`}
            list={histories
              .filter((history) => history.type === 'path')
              .map((h, index) => ({
                id: index,
                title: `${h.placeAdress.country}, ${h.placeAdress.locality}`,
                type: h.nameUser,
                time: +h.createdAt,
                status: getStatus(h.statut),
              }))}
          />
        )}
      </Container>
    </PageLayout>
  );
};

export default SuiviColisSearchPage;
