import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import SimpleLayout from './layouts/simple';
//
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import DeparturesPage from './pages/DeparturesPage';
import TarifsPage from './pages/TarifsPage';
import SendColisPage from './pages/SendColisPage';
import LoginRoute from './LoginRoute';
import PrivateRoute from './PrivateRoute';
import ListColis from './pages/ListeColis';
// eslint-disable-next-line import/no-named-as-default
import SuiviColis from './pages/SuiviColis';
import AccountLayout from './layouts/account';
import ProfilePage from './pages/ProfilePage';
import { FicheColis } from './pages/FicheColis';
import CorrenspondentsPage from './pages/CorrenspondentsPage';
import SuiviColisSearchPage from "./pages/SuiviColisSearchPage";
import SuiviColisDirectLink from "./pages/SuiviColisDirectLink";

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/',
      element: <Navigate to="/departs" />,
      index: true,
    },
    {
      path: '/departs',
      element: <DeparturesPage />,
    },
    {
      path: '/tarifs',
      element: <TarifsPage />,
    },
    {
      path: '/suivi-colis',
      element: <SuiviColisSearchPage />,
    },
    {
      path: '/suivi-colis/:code',
      element: <SuiviColisDirectLink />,
    },
    {
      path: '/expedier-colis',
      element: (
        <PrivateRoute redirect="/expedier-colis">
          <SendColisPage />
        </PrivateRoute>
      ),
    },
    {
      path: '/login',
      element: (
        <LoginRoute>
          <LoginPage />
        </LoginRoute>
      ),
    },
    {
      path: '/dashboard',
      element: (
        <PrivateRoute redirect="/dashboard">
          <AccountLayout />
        </PrivateRoute>
      ),
      children: [
        { path: '/dashboard', element: <Navigate to="/dashboard/user" /> },
        { path: 'user', element: <ProfilePage /> },
        { path: 'correspondents', element: <CorrenspondentsPage /> },
      ],
    },
    {
      path: '/liste-colis',
      element: (
        <PrivateRoute>
          <ListColis />
        </PrivateRoute>
      ),
    },
    {
      path: '/history-colis/:colisId',
      element: (
        <PrivateRoute>
          <SuiviColis />
        </PrivateRoute>
      ),
    },
    {
      path: '/update-colis/:colisId',
      element: (
        <PrivateRoute>
          <SendColisPage />
        </PrivateRoute>
      ),
    },
    {
      path: '/fiche-colis/:colisId',
      element: (
        <PrivateRoute>
          <FicheColis />
        </PrivateRoute>
      ),
    },
    {
      element: <SimpleLayout />,
      children: [
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
