import React, { forwardRef } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  Slide,
  Stack,
  Typography,
} from '@mui/material';
import Iconify from '../iconify';
import { fDate } from '../../utils/formatTime';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const CorrespondentDialog = ({ open, onClose, selectedCorrespondent, correspondentsAddress }) => (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      fullWidth
      keepMounted
      maxWidth="md"
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <Stack spacing={1}>
              <Stack direction="row" spacing={2} alignContent="center" alignItems="center">
                <Iconify icon="ph:user-circle-thin" color="primary" width={50} />
                <Typography variant="h4" gutterBottom component="div" maxWidth={20} color="primary" flexWrap>
                  {selectedCorrespondent.name}
                </Typography>
              </Stack>
              <Stack p={1} bgcolor="grey.200">
                <Typography variant="caption" display="block" color="grey.500">
                  Prénom
                </Typography>
                <Typography variant="subtitle2">{selectedCorrespondent.surname}</Typography>
              </Stack>
              {selectedCorrespondent.email && (
                <Stack p={1} bgcolor="grey.200">
                  <Typography variant="caption" display="block" color="grey.500">
                    Email
                  </Typography>
                  <Typography variant="subtitle2">{selectedCorrespondent.email}</Typography>
                </Stack>
              )}
              <Stack p={1} bgcolor="grey.200">
                <Typography variant="caption" display="block" color="grey.500">
                  Numéro
                </Typography>
                <Typography variant="subtitle2">{selectedCorrespondent.phone}</Typography>
              </Stack>
              <Box>
                <Typography
                  variant="h6"
                  color="grey.500"
                  sx={{
                    borderBottom: '0.2em solid',
                    borderColor: 'grey.500',
                    padding: '0.4em 0',
                  }}
                >
                  Contactez
                </Typography>
                <Stack direction="row" spacing={2}>
                  <IconButton
                    color="success"
                    onClick={() => {
                      window.open(`https://wa.me/${selectedCorrespondent.phone.slice(1)}?text=Bonjour`, '_blank');
                    }}
                  >
                    <Iconify icon="ic:baseline-whatsapp" color="primary" width={30} />
                  </IconButton>
                  <IconButton
                    color="secondary"
                    onClick={() => {
                      window.open(`tel:${selectedCorrespondent.phone}`, '_blank');
                    }}
                  >
                    <Iconify icon="material-symbols:phone-in-talk-outline" color="primary" width={30} />
                  </IconButton>
                  {selectedCorrespondent.email && (
                    <IconButton
                      color="primary"
                      onClick={() => {
                        window.open(`mailto:${selectedCorrespondent.email}`, '_blank');
                      }}
                    >
                      <Iconify icon="ic:round-email" color="primary" width={30} />
                    </IconButton>
                  )}
                </Stack>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Stack spacing={3}>
              <Typography variant="h6" color="grey.500" fontWeight="fontWeightBold" gutterBottom>
                Addresses de votre correspondant
              </Typography>
              {correspondentsAddress.map((address, index) => (
                <Stack p={1} bgcolor="grey.200" key={index}>
                  <Typography variant="caption" display="block" color="grey.500">
                    Adresse {index + 1}
                  </Typography>
                  <Typography variant="subtitle2">
                    <Link href="#" underline="none">
                      {address.country}, {address.locality} {}
                    </Link>
                  </Typography>
                </Stack>
              ))}
              <Stack p={1}>
                <Typography variant="caption" display="block" color="grey.500">
                  Crée le
                </Typography>
                <Typography variant="subtitle2">{fDate(+selectedCorrespondent.createdAt)}</Typography>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Fermer</Button>
      </DialogActions>
    </Dialog>
  );

export default CorrespondentDialog;
