// @mui
import { styled } from '@mui/material/styles';
// components
import {
  Box,
  Container,
  Divider,
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Typography,
} from '@mui/material';
import React from 'react';
import { NavLink as RouterLink, Outlet } from 'react-router-dom';
import Iconify from '../../components/iconify';
import { auth } from '../../firebase';
import TopHeader from '../../components/top-header';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
}));

// ----------------------------------------------------------------------

const AccountLayout = () => (
  <StyledRoot>
    <Main>
      <TopHeader />

      <Container>
        <Grid container spacing={2} mt={5}>
          <Grid item xs={12} md={4}>
            <Paper sx={{ maxWidth: '100%', margin: 'auto', mr: { md: 3 } }}>
              <MenuList>
                <MenuItem
                  sx={{
                    p: 2,
                    '&.active': {
                      color: 'primary.contrastText',
                      backgroundColor: 'primary.main',
                      fontWeight: 'typography.fontWeightBold',
                    },
                    '&.active>.MuiListItemIcon-root': {
                      color: 'primary.contrastText'
                    },
                  }}
                  component={RouterLink}
                  to="/dashboard/user"
                >
                  <ListItemIcon color="inherit">
                    <Iconify icon="iconoir:profile-circle" />
                  </ListItemIcon>
                  <ListItemText>Mon profil</ListItemText>
                  <Typography variant="body2" color="text.secondary" />
                </MenuItem>
                <MenuItem
                  sx={{
                    p: 2,
                    '&.active': {
                      color: 'primary.contrastText',
                      backgroundColor: 'primary.main',
                      fontWeight: 'typography.fontWeightBold',
                    },
                    '&.active>.MuiListItemIcon-root': {
                      color: 'primary.contrastText'
                    },
                  }}
                  component={RouterLink}
                  to="/dashboard/correspondents"
                >
                  <ListItemIcon color="inherit">
                    <Iconify icon="ph:users-three-thin" />
                  </ListItemIcon>
                  <ListItemText>Mes Correspondants</ListItemText>
                  <Typography variant="body2" color="text.secondary" />
                </MenuItem>
                <Divider />
                <MenuItem
                  onClick={async () => {
                    await auth.signOut();
                  }}
                >
                  <ListItemIcon>
                    <Iconify icon="ri:logout-circle-line" />
                  </ListItemIcon>
                  <ListItemText>Déconnexion</ListItemText>
                </MenuItem>
              </MenuList>
            </Paper>
          </Grid>
          <Grid item xs={12} md={8}>
            <Outlet />
          </Grid>
        </Grid>
        <Box mt={5} />
      </Container>
      <Box mt={5} />
    </Main>
  </StyledRoot>
);

export default AccountLayout;
