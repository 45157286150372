const { query, getDoc, doc, collection, getDocs, orderBy, where, limit } = require('firebase/firestore');
const { db } = require('../firebase');

export const getColisInfo = async (colisId) => {
  const ref = query(doc(db, 'enterprises', 'tgvairwabo', 'colis', colisId));
  const querySnapshot = await getDoc(ref);

  return querySnapshot.data();
};

export const getColisInfoByCode = async (code) => {
  const ref = query(collection(db, 'enterprises', 'tgvairwabo', 'colis'), where('code', '==', code.toUpperCase()), limit(1));
  const querySnapshot = await getDocs(ref);
  const colis = querySnapshot.docs.map((doc) => doc.data());
  const colisInfo = colis[0];
  return colisInfo;
};

export const getAllHistory = async (colisId) => {
    const ref = query(
    collection(db, 'enterprises', 'tgvairwabo', 'colis', colisId, 'history'),
    orderBy('createdAt', 'desc')
  );
  const querySnapshot = await getDocs(ref);

  console.log(querySnapshot.docs.map((value) => value.data()))

  return querySnapshot.docs.map((value) => value.data());

};

