import { collection, getDoc, query, doc, where, getDocs } from 'firebase/firestore';
import { httpsCallable } from "firebase/functions"

import md5 from 'md5';
import { db, functions } from "../firebase";
import { getCurrentUser } from './login';

const getClient = async (number) => {
  const user = getCurrentUser();
  const id = md5(`${number}_${user.uid}`);
  const ref = query(doc(db, 'enterprises', 'tgvairwabo', 'clients', id));
  const querySnapshot = await getDoc(ref);
  console.log(number);
  console.log(id);
  console.log(querySnapshot.data());
  if (querySnapshot.exists()) {
    return querySnapshot.data();
  }
  return null;
};

const getClientAddress = async (number, type = 'sender') => {
  const user = getCurrentUser();
  const clientId = md5(`${number}_${user.uid}`);

  const ref = query(
    collection(db, 'enterprises', 'tgvairwabo', 'address'),
    where('typeAddress', '==', type),
    where('idClient', '==', clientId)
  );
  const querySnapshot = await getDocs(ref);
  return querySnapshot.docs.map((doc) => doc.data());
};

const formatAddress = (add) => {
  const address = add.formatted_address;
  /* eslint-disable */
  const country = add.address_components.find((component) => component.types.includes('country'))?.long_name ?? '';
  const country_code =
    add.address_components.find((component) => component.types.includes('country'))?.short_name ?? '';
  const postal_code =
    add.address_components.find((component) => component.types.includes('postal_code'))?.long_name ?? '';
  const route = add.address_components.find((component) => component.types.includes('route'))?.long_name ?? '';
  const locality = add.address_components.find((component) => component.types.includes('locality'))?.long_name ?? '';
  const administrative_area_level_1 =
    add.address_components.find((component) => component.types.includes('administrative_area_level_1'))?.long_name ??
    '';
  const administrative_area_level_2 =
    add.address_components.find((component) => component.types.includes('administrative_area_level_2'))?.long_name ??
    '';
  const lat = add.geometry.location?.lat().toString() ?? '';
  const lon = add.geometry.location?.lng().toString() ?? '';
  return {address, country, country_code, postal_code, route, locality, administrative_area_level_1, administrative_area_level_2, lat, lon}
};

const sendColis = async (colis, records) => {
  const addColis = httpsCallable(functions, 'createColisV1_4')
  const result = await addColis({
    colis: colis,
    histories: records,
    enterpriseId: "tgvairwabo"
  })

  console.log(result)

  return result
}

const updateColis = async (colis, records) => {
  const addColis = httpsCallable(functions, 'updateColisV1_4')
  const result = await addColis({
    colis: colis,
    histories: records,
    enterpriseId: "tgvairwabo"
  })

  console.log(result)

  return result
}

const getColis = async (colisId) => {
  const ref = query(doc(db, 'enterprises', 'tgvairwabo', 'colis', colisId));
  const querySnapshot = await getDoc(ref);
  if (querySnapshot.exists()) {
    return querySnapshot.data();
  }
  return null;
}


export { getClient, getClientAddress, formatAddress, sendColis, getColis, updateColis };
