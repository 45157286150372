// @mui
import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Slide,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Loader from '../components/loader/Loader';
import PageLayout from '../layouts/page/PageLayout';
import Iconify from '../components/iconify';
import EmptyBox from '../components/empty-box';
import { getAsignPath, getTypesColis } from "../services/paths";

// ----------------------------------------------------------------------

export default function TarifsPage() {
  const [paths, setPaths] = useState([]);
  const [typesColis, setTypesColis] = useState([]);
  const [selectedPath, setSelectedPath] = useState(null);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getAsignPath().then((paths) => {
      setPaths(paths);
    });
    return () => {};
  }, []);

  const { handleSubmit } = useForm({
    reValidateMode: 'onBlur',
  });

  const onSubmitForm = (values) => {
    console.log(values);
  };

  const getAndShowTypesColis = (id) => {
    setTypesColis([]);
    setLoading(true);
    getTypesColis(id).then((types) => {
      setTypesColis(types);
      setLoading(false)

    });
  };

  return (
    <PageLayout title="Nos tarifs">
      <Container>
        <Stack direction="row" sx={{ my: 5 }}>
          <Typography variant="h4">Nos tarfis</Typography>
          <Box sx={{ flexGrow: 1 }} />
        </Stack>

        <Slide direction="up" in mountOnEnter unmountOnExit>
          <Card sx={{ mb: 3 }}>
            <CardHeader
              disableTypography
              avatar={<Iconify icon="ph:path-fill" />}
              title={
                <Link to="#" variant="subtitle2" color="primary" underline="none">
                  Selectionner votre trajet
                </Link>
              }
            />
            <CardContent>
              <form onSubmit={handleSubmit(onSubmitForm)}>
                <Stack spacing={2}>
                  <Autocomplete
                    options={paths}
                    getOptionLabel={(option) => `${option.startAddress.country} - ${option.arrivalAddress.country}`}
                    onChange={(_, value) => {
                      getAndShowTypesColis(value.id);
                      setSelectedPath(value);
                    }}
                    renderInput={(params) => <TextField {...params} label="Sélectionner un trajet" />}
                  />
                </Stack>
              </form>
            </CardContent>
          </Card>
        </Slide>

        {selectedPath ? (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} onClick={null}>
              <Card sx={{ '&:hover': { border: 1, borderColor: 'primary.light', cursor: 'pointer' } }}>
                <CardHeader
                  disableTypography
                  avatar={<Iconify icon="game-icons:path-distance" width={25} height={25} />}
                  title={
                    <Link to="#" variant="subtitle2" color="primary" underline="none">
                      <Stack direction="row" spacing={2}>
                        <Typography variant="body2" color="primary" textAlign="justify">
                          {selectedPath.startAddress.country}
                        </Typography>
                        <Iconify icon="gg:airplane" />
                        <Typography variant="body2" color="primary" textAlign="justify">
                          {selectedPath.arrivalAddress.country}
                        </Typography>
                      </Stack>
                    </Link>
                  }
                />
                <CardContent>
                  <List>
                    {loading && (
                      <Loader/>
                    )}
                    {typesColis.length  === 0 && !loading && (
                      <EmptyBox title="Aucun tarif pour ce trajet" />
                    )}
                    {typesColis.map((type) => (
                      <ListItem
                        key={type.id}
                        disablePadding
                        secondaryAction={
                          <Typography>
                            {type.priceRef} {type.currency}
                          </Typography>
                        }
                      >
                        <ListItemButton>
                          <ListItemIcon>
                            <Iconify icon="lucide:circle-dot" />
                          </ListItemIcon>
                          <ListItemText>{type.name}</ListItemText>
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        ) : (
          <EmptyBox title="Selectionner un trajet" icon="ph:path-fill" />
        )}
      </Container>
    </PageLayout>
  );
}
