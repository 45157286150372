import React from 'react';
import { Box, CircularProgress } from '@mui/material';

const Loader = () => (
    <Box flexDirection="column" sx={{ display: 'flex', alignItems: 'center', width: 1 }}>
      <CircularProgress />
    </Box>
  );

export default Loader;
