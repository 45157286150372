// @mui
import {
  Container,
  Button,
  Grid,
  Stack,
  Typography,
  IconButton,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PageLayout from '../layouts/page/PageLayout';
import { fDate } from '../utils/formatTime';
import EmptyBox from '../components/empty-box';
import { getCurrentUser } from '../services/login';
import Iconify from '../components/iconify';
import Loader from '../components/loader/Loader';
import { getAllColis } from '../services/colis';
import ColisItem from '../components/colis-item/ColisItem';

// ----------------------------------------------------------------------

export default function ListColis() {
  const [searchText, setSearchText] = useState('');

  // for list des colis
  const [allColis, setAllColis] = useState([]);
  const [store, setStore] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openSearch, setOpenSearch] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const user = getCurrentUser();
    getAllColis(user.uid, user.phoneNumber).then((value) => {
      setAllColis(value);
      setStore(value);
      setLoading(false);
    });
    return () => {};
  }, []);

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchText(value);

    const newCorrespondent = store.filter((key) =>
      `${JSON.stringify(key)}${fDate(+key.createdAt)}${key.code}`.toLowerCase().includes(value.toLowerCase())
    );

    setAllColis(newCorrespondent);
  };

  const handleCloseSearch = () => {
    if (searchText === '') setOpenSearch(false);
    setSearchText('');
    setAllColis(store);
  };

  return (
    <PageLayout title="Mes colis">
      <Container>
        <Stack direction="column" sx={{ my: 5 }} spacing={2}>
          <Typography variant="h4">Liste Des Colis</Typography>
          {!openSearch && (
            <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end">
              <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
                <Button
                  variant="contained"
                  startIcon={<Iconify icon="eva:search-fill" />}
                  onClick={() => setOpenSearch(true)}
                >
                  Rechercher un colis
                </Button>
              </Stack>
            </Stack>
          )}
        </Stack>
        {/* this is for filters */}
        {openSearch && (
          <Grid item xs={12} sm={12} md={12} mb={3}>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="search">Rechercher un colis</InputLabel>
              <OutlinedInput
                id="search"
                type="text"
                value={searchText}
                onChange={handleSearch}
                startAdornment={
                  <InputAdornment position="start">
                    <IconButton aria-label="toggle password visibility" edge="end">
                      <Iconify icon="ic:baseline-search" />
                    </IconButton>
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility" onClick={handleCloseSearch} edge="end">
                      <Iconify icon="ion:close-outline" />
                    </IconButton>
                  </InputAdornment>
                }
                label="Rechercher un colis"
              />
            </FormControl>
          </Grid>
        )}

        <Grid container spacing={3}>
          {loading && <Loader />}
          {allColis.length === 0 && !loading && <EmptyBox title="Aucun colis" />}
          {allColis.map((colis) => (
            <Grid item xs={12} sm={12} md={6} key={colis.id}>
              <ColisItem colis={colis} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </PageLayout>
  );
}
