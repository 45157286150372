import React from 'react';
import { Navigate } from 'react-router-dom';
import { useFirebaseAuth } from './contexts/FirebaseAuthContext';

const LoginRoute = ({ children }) => {
  const { user, userExist } = useFirebaseAuth();

  return (!user || !userExist) ? children : <Navigate to="/" />;
};

export default LoginRoute;
