// @mui

import React from 'react';
import { SnackbarProvider } from 'notistack';
import HelmetTitle from '../components/helmet-title/HelmetTitle';
import Profile from '../components/profile/Profile';
import { getAndSaveCurrentPosition, loadMapScript } from '../services/geocode';

// ----------------------------------------------------------------------

export default function ProfilePage() {
  const loaded = React.useRef(false);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadMapScript();
    }

    loaded.current = true;

    getAndSaveCurrentPosition();
  }

  return (
    <SnackbarProvider maxSnack={5}>
      <HelmetTitle title="Profile" />

      <Profile />
    </SnackbarProvider>
  );
}
