import React from 'react';
import { Avatar, Box, Button, Card, CardActions, CardContent, CardHeader, Stack, Typography } from '@mui/material';
import Iconify from '../iconify';

const CorrespondentCard = ({ correspondent, onClick }) => (
    <Card sx={{ '&:hover': { border: 1, borderColor: 'primary.light', cursor: 'pointer' } }}>
      <CardHeader
        disableTypography
        avatar={<Avatar sx={{ bgcolor: 'primary.main' }}>{correspondent?.name?.charAt(0).toUpperCase()}</Avatar>}
        title={''}
      />
      <CardContent>
        <Stack p={1} bgcolor="grey.200">
          <Typography variant="caption" display="block" color="grey.500">
            Nom
          </Typography>
          <Typography variant="subtitle2">{correspondent.name}</Typography>
        </Stack>
        <Stack p={1} mt={1} bgcolor="grey.200">
          <Typography variant="caption" display="block" color="grey.500">
            Prénom
          </Typography>
          <Typography variant="subtitle2">{correspondent.surname}</Typography>
        </Stack>
        {correspondent.email && (
          <Stack p={1} mt={1} bgcolor="grey.200">
            <Typography variant="caption" display="block" color="grey.500">
              Email
            </Typography>
            <Typography variant="subtitle2">{correspondent.email}</Typography>
          </Stack>
        )}
        <Stack p={1} mt={1} bgcolor="grey.200">
          <Typography variant="caption" display="block" color="grey.500">
            Numéro
          </Typography>
          <Typography variant="subtitle2">{correspondent.phone}</Typography>
        </Stack>
      </CardContent>
      <CardActions sx={{ px: 2 }}>
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          onClick={() => onClick()}
          startIcon={<Iconify icon="ic:baseline-more-vert" />}
        >
          Voir tout
        </Button>
        <Box sx={{ flexGrow: 1 }} />
      </CardActions>
    </Card>
  );

export default CorrespondentCard;
