import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Stack,
  Typography,
} from '@mui/material';

import { useParams } from 'react-router-dom';
import { getAllHistory, getColisInfoByCode } from '../services/suivi';
import PageLayout from '../layouts/page/PageLayout';
import { getStatus } from '../services/colis';
import HistoryTimeline from '../components/history-timeline/HistoryTimeline';
import Loader from '../components/loader/Loader';

const SuiviColisSearchPage = () => {
  const { code } = useParams();

  const [colisInfo, setcolisInfo] = useState({});
  const [histories, setHistories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    getColis(code);
    return () => {};
  }, []);

  const getColis = (code) => {
    setLoading(true);
    setError(false);
    getColisInfoByCode(code).then((value) => {
      if (!value) {
        setLoading(false);
        setError(true);
        return;
      }
      setcolisInfo(value);
      getAllHistory(value.id).then((value) => {
        setHistories(value);
        setError(false);
        setLoading(false);
      });
    });
  };
  

  return (
    <PageLayout title="Suivi colis">
      <Container>
        <Stack direction="column" sx={{ my: 5 }} spacing={2}>
          <Typography variant="h4">Suivi Colis</Typography>
        </Stack>
        <Grid container spacing={3}>
          {loading && <Loader />}
        </Grid>
        {error && (
          <Typography variant="h5" textAlign="center">
            Aucun colis trouvé
          </Typography>
        )}
        {histories.length > 0 && (
          <HistoryTimeline
            title={`Parcours du colis ${code.toUpperCase()}`}
            list={histories
              .filter((history) => history.type === 'path')
              .map((h, index) => ({
                id: index,
                title: `${h.placeAdress.country}, ${h.placeAdress.locality}`,
                type: h.nameUser,
                time: +h.createdAt,
                status: getStatus(h.statut),
              }))}
          />
        )}
      </Container>
    </PageLayout>
  );
};

export default SuiviColisSearchPage;
