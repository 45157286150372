import { collection, getDocs, query, where, orderBy } from 'firebase/firestore';
import { uniqBy } from 'lodash';
import { db } from '../firebase';

const getDepartures = async () => {
  const dateNow = new Date();
  const ref = query(
    collection(db, 'enterprises', 'tgvairwabo', 'departures'),
    where('date', '>=', dateNow.getTime().toString()),
    orderBy('date', 'asc')
  );
  const querySnapshot = await getDocs(ref);
  const departures = querySnapshot.docs.map((doc) => doc.data());
  return departures;
};

const getAgencies = async () => {
  const q = query(collection(db, 'enterprises', 'tgvairwabo', 'agencies'));
  const querySnapshot = await getDocs(q);
  const agencies = querySnapshot.docs.map((doc) => doc.data());

  return uniqBy(agencies, (a) => a.address.country);
};

export { getDepartures, getAgencies };
