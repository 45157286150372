import React from 'react';
import { TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const FormDatePicker = ({ name, control, rules = { required: true }, label, ...others }) => (
  <Controller
    control={control}
    name={name}
    rules={rules}
    defaultValue={new Date()}
    render={({ field: { ref, onChange, value, ...field } }) => (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label={label}
          value={value}
          onChange={onChange}
          renderInput={(params) => <TextField name={name} inputRef={ref} {...field} {...params} {...others} />}
        />
      </LocalizationProvider>
    )}
  />
);

export default FormDatePicker;
