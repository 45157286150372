import React from 'react';
import { Navigate } from 'react-router-dom';
import { useFirebaseAuth } from './contexts/FirebaseAuthContext';

const PrivateRoute = ({ redirect, children }) => {
  const {user, userExist} = useFirebaseAuth();

  return (user && userExist) ? children : <Navigate to={`/login?redirect=${redirect}${!userExist ? "&type=new-user" : ""}`}  />;
};

export default PrivateRoute;
