import React, { useState } from 'react';
import { NavLink as RouterLink, useNavigate } from 'react-router-dom';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  Stack,
  Toolbar,
  useMediaQuery,
} from '@mui/material';
import Logo from '../logo';
import { StyledHeader, StyledToolbar, StyledTopNavLink } from './styles';
import { useFirebaseAuth } from '../../contexts/FirebaseAuthContext';
import Iconify from '../iconify';

const TopHeader = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const { user } = useFirebaseAuth();
  const [open, setOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setOpen(open);
  };

  const list = () => (
    <Box sx={{ width: 'auto' }} role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
      <List>
        <ListItem>
          <StyledTopNavLink
            underline="none"
            color="text.primary"
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              window.location.href = `https://tgvairwabo.com`;
            }}
          >
            Accueil
          </StyledTopNavLink>
        </ListItem>
        <ListItem>
          <StyledTopNavLink to="/suivi-colis" underline="none" color="text.primary" component={RouterLink}>
            Suivre un colis
          </StyledTopNavLink>
        </ListItem>
        <ListItem>
          <StyledTopNavLink to="/departs" underline="none" color="text.primary" component={RouterLink}>
            Départs
          </StyledTopNavLink>
        </ListItem>
        <ListItem>
          <StyledTopNavLink to="/tarifs" underline="none" color="text.primary" component={RouterLink}>
            Tarifs
          </StyledTopNavLink>
        </ListItem>
        <ListItem>
          <StyledTopNavLink to="/expedier-colis" underline="none" color="text.primary" component={RouterLink}>
            Expedition
          </StyledTopNavLink>
        </ListItem>
        {user && (
          <ListItem>
            <StyledTopNavLink to="/liste-colis" underline="none" color="text.primary" component={RouterLink}>
              Mes colis
            </StyledTopNavLink>
          </ListItem>
        )}
        <ListItem>
          {user ? (
            <Avatar
              onClick={() => {
                navigate('/dashboard');
              }}
              sx={{ bgcolor: 'primary.main', cursor: 'pointer' }}
            >
              {user.name.charAt(0).toUpperCase()}
              {user.surname.charAt(0).toUpperCase()}
            </Avatar>
          ) : (
            <Button variant="contained" component={RouterLink} to="/login">
              Connexion
            </Button>
          )}
        </ListItem>
      </List>
    </Box>
  );

  return isMobile ? (
    <Toolbar>
      <Box sx={{ flexGrow: 1 }}>
        <Logo
          disabledLink
          sx={{ minWidth: 45, minHeight: 45, cursor: 'pointer', mt: 1 }}
          onClick={() => {
            window.location.href = `https://tgvairwabo.com`;
          }}
        />
      </Box>
      <IconButton onClick={() => setOpen(true)}>
        <Iconify icon="mdi:menu" width={35} />
      </IconButton>
      <Drawer anchor="top" open={open} onClose={toggleDrawer(false)}>
        {list()}
      </Drawer>
    </Toolbar>
  ) : (
    <StyledHeader position="static">
      <StyledToolbar>
        <Box sx={{ flexGrow: 1 }} />
        <Logo
          disabledLink
          sx={{ minWidth: 70, minHeight: 70, cursor: 'pointer' }}
          onClick={() => {
            window.location.href = `https://tgvairwabo.com`;
          }}
        />
        <Box sx={{ flexGrow: 1 }} />
        
        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 2,
            sm: 4,
          }}
        >
          <StyledTopNavLink
            underline="none"
            color="text.primary"
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              window.location.href = `https://tgvairwabo.com`;
            }}
          >
            Accueil
          </StyledTopNavLink>
          <StyledTopNavLink to="/suivi-colis" underline="none" color="text.primary" component={RouterLink}>
            Suivre un colis
          </StyledTopNavLink>
          <StyledTopNavLink to="/departs" underline="none" color="text.primary" component={RouterLink}>
            Départs
          </StyledTopNavLink>
          <StyledTopNavLink to="/tarifs" underline="none" color="text.primary" component={RouterLink}>
            Tarifs
          </StyledTopNavLink>
          <StyledTopNavLink to="/expedier-colis" underline="none" color="text.primary" component={RouterLink}>
            Expédition
          </StyledTopNavLink>
          {user && (
            <StyledTopNavLink to="/liste-colis" underline="none" color="text.primary" component={RouterLink}>
              Mes colis
            </StyledTopNavLink>
          )}

          <Divider orientation="vertical" flexItem />
          {user ? (
            <Avatar
              onClick={() => {
                navigate('/dashboard');
              }}
              sx={{ bgcolor: 'primary.main', cursor: 'pointer' }}
            >
              {user.name.charAt(0).toUpperCase()}
              {user.surname.charAt(0).toUpperCase()}
            </Avatar>
          ) : (
            <Button variant="contained" component={RouterLink} to="/login">
              Connexion
            </Button>
          )}
          {/* <Button variant="contained" color="secondary">
              Inscription
            </Button> */}
        </Stack>
        <Box sx={{ flexGrow: 1 }} />
      </StyledToolbar>
    </StyledHeader>
  );
};

export default TopHeader;
