// routes
import { Box } from '@mui/material';
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/scroll-to-top';
import { StyledChart } from './components/chart';
import { FirebaseAuthProvider } from "./contexts/FirebaseAuthContext";

// ----------------------------------------------------------------------

export default function App() {
  return (
    <ThemeProvider>
      <FirebaseAuthProvider>
        <Box sx={{ display: 'none' }}>
          <div id="recptcha-container">Captcha</div>
        </Box>
        <ScrollToTop />
        <StyledChart />
        <Router />
      </FirebaseAuthProvider>
    </ThemeProvider>
  );
}
