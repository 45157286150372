import React from 'react';
import { Box, Button, Card, CardActions, CardContent, CardHeader, Chip, Link, Stack, Typography } from '@mui/material';
import { useNavigate } from "react-router-dom";
import Iconify from '../iconify';
import { fDate } from '../../utils/formatTime';
import { getStatus, getStatusPayment } from '../../services/colis';

const ColisItem = ({ colis }) => {
  const navigate = useNavigate();

  return (
    <Card sx={{ '&:hover': { border: 1, borderColor: 'primary.light', cursor: 'pointer' } }}>
      <CardHeader
        disableTypography
        avatar={<Iconify icon="vaadin:package" width={20} height={20} color="primary" />}
        title={
          <Link to="#" variant="subtitle2" color="primary" underline="none">
            {colis.code}
          </Link>
        }
        action={
          <Link to="#" variant="subtitle2" color="secondary" underline="none">
            {fDate(+colis.createdAt)}
          </Link>
        }
        onClick={() => navigate(`/fiche-colis/${colis.id}`)}
      />
      <CardContent onClick={() => navigate(`/fiche-colis/${colis.id}`)}>
        <Stack direction="row" spacing={2} p={1}>
          <Typography variant="body2" color="text.primary" textAlign="justify">
            {colis.description}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={2} p={1}>
          <Iconify icon="geo:turf-destination" width={25} />
          <Typography variant="body2" color="text.primary" textAlign="justify">
            {`${colis.addressArrival.country}, ${colis.addressArrival.locality}`}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={2} p={1}>
          <Chip variant="outlined" color={getStatus(colis.status).color} label={getStatus(colis.status).name} />
          <Chip
            variant="outlined"
            color={getStatusPayment(colis.paid).color}
            label={getStatusPayment(colis.paid).name}
          />
        </Stack>
      </CardContent>
      <CardActions sx={{ px: 2 }} onClick={() => navigate(`/history-colis/${colis.id}`)}>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={() => navigate(`/history-colis/${colis.id}`)}
          endIcon={<Iconify icon="mdi:clipboard-text-history-outline" />}
        >
          Suivi
        </Button>
        <Box sx={{ flexGrow: 1 }} />
      </CardActions>
    </Card>
  );
};

export default ColisItem;
