import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
// @mui
import { Stack, TextField, Typography, useMediaQuery } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { MuiOtpInput } from 'mui-one-time-password-input';
import { checkUserExist, confirmCode } from '../../../services/login';

// ----------------------------------------------------------------------

export default function OtpForm() {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  console.log(searchParams.get('redirect'));

  const [otp, setOtp] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleOtpChange = (newOtp) => {
    setOtp(newOtp);
  };

  const handleComplete = async (otp) => {
    setError(false);
    setLoading(true);
    try {
      const userId = await confirmCode(otp);
      const userExist = await checkUserExist(userId);
      // console.log({ userExist })
      // searchParams.append('type', 'new-user');
      if (userExist) {
        navigate(searchParams.get('redirect') ?? '/departs');
      } else {
        searchParams.append('type', 'new-user');
      }
    } catch (e) {
      setError(true);
      console.error(e);
    }
    setLoading(false);
  };
  const handleClick = async () => {
    await handleComplete(otp);
  };

  return (
    <>
      <Stack spacing={3} mt={3}>
        {isMobile ? (
          <TextField
            id="code-otp"
            label="Entre votre code"
            variant="standard"
            onChange={async (e) => {
              const { value } = e.target;
              handleOtpChange(value);
              if (value.length >= 6) {
                await handleComplete(value);
              }
            }}
          />
        ) : (
          <MuiOtpInput
            length={6}
            value={otp}
            onChange={handleOtpChange}
            onComplete={handleComplete}
          />
        )}
      </Stack>

      {error && (
        <Typography variant="caption" display="block" color="error" gutterBottom>
          Verifier le code
        </Typography>
      )}

      <LoadingButton
        loading={loading}
        sx={{ mt: 3 }}
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        onClick={handleClick}
      >
        Connexion
      </LoadingButton>
    </>
  );
}
