import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { doc, getDoc, query, setDoc } from 'firebase/firestore';
import { auth, db } from '../firebase';
import { fTimestamp } from '../utils/formatTime';

const checkRecaptcha = () => {
  window.recaptchaVerifier = new RecaptchaVerifier(
    'recptcha-container',
    {
      size: 'invisible',
      callback: (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        console.log(response);
      },
    },
    auth
  );
};

const sendCode = async (phoneNumber) => {
  const appVerifier = window.recaptchaVerifier;

  console.log(phoneNumber);

  try {
    const confirmationResult = await signInWithPhoneNumber(auth, phoneNumber, appVerifier);
    window.confirmationResult = confirmationResult;
  } catch (e) {
    console.log(e);
  }
};

const confirmCode = async (code) => {
  try {
    const result = await window.confirmationResult.confirm(code);
    console.log(result.user.uid);
    return result.user.uid;
  } catch (e) {
    console.log(e);
    throw Error('Failed to confirm code.');
  }
};

const getCurrentUser = () => {
  const user = auth.currentUser;
  return user;
};

const refreshUser = async () => {
  await auth.currentUser.reload()
}

const getUser = async (userId) => {
  const ref = doc(db, 'users', userId);
  const q = query(ref);

  const querySnapshot = await getDoc(q);
  return querySnapshot.data();
};

const saveUser = async (data) => {
  const user = getCurrentUser();
  const ref = doc(db, 'users', user.uid);
  const q = query(ref);
  const code = fTimestamp().toString(36).toUpperCase();
  const role = 1;
  const invitation = 1;
  const createdAt = fTimestamp().toString();
  const roleList = {};
  const phone = user.phoneNumber;
  await setDoc(q, { ...data, code, role, invitation, roleList, phone, createdAt, idEnterprise: 'tgvairwabo' }, { merge: true });
};

const updateUser = async (data) => {
  const user = getCurrentUser();
  const ref = doc(db, 'users', user.uid);
  const q = query(ref);
  await setDoc(q, { ...data }, { merge: true });
}

const checkUserExist = async (userId) => {
  const ref = doc(db, 'users', userId);
  const q = query(ref);
  const querySnapshot = await getDoc(q);
  return querySnapshot.exists();
};

export { checkRecaptcha, confirmCode, sendCode, getCurrentUser, getUser, checkUserExist, saveUser, updateUser, refreshUser };
