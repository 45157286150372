import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase";
import { getCurrentUser } from "./login";

const getCorrespondents = async (data) => {
  const user = getCurrentUser();
  const q = query(collection(db, "enterprises", "tgvairwabo", "clients"), where("userId", "==", user.uid))
  const querySnapshot = await getDocs(q);
  return  querySnapshot.docs.map((doc) => doc.data())
}

const getCorrespondentAddress = async (clientId) => {
  const user = getCurrentUser();
  const ref = query(
    collection(db, 'enterprises', 'tgvairwabo', 'address'),
    where('idClient', '==', clientId)
  );
  const querySnapshot = await getDocs(ref);
  return  querySnapshot.docs.map((doc) => doc.data());
};


export {
  getCorrespondents,
  getCorrespondentAddress
}