import { geocodeByLatLng, geocodeByPlaceId } from 'react-google-places-autocomplete';
import { formatAddress } from './expedition';

const GOOGLE_MAPS_API_KEY = 'AIzaSyAnyNCALPCrYfC0w7PET09AhpVXYxLwy5g';

const getPlaceDetails = async (place, type = '') => {
  try {
    const results = await geocodeByPlaceId(place.place_id);
    const firstResult = results[0];
    const addressFormatted = formatAddress(firstResult);
    const address = {
      inputAddress: place?.description ?? '',
      idGooglePlace: place?.place_id ?? '',
      typeAddress: type,
      ...addressFormatted,
    };
    return address;
  } catch (e) {
    console.error(e);
    throw Error('Failed to get address details');
  }
};

const saveCurrentPosition = async ({ lat, lng }) => {
  const results = await geocodeByLatLng({ lat, lng });
  const firstResult = results[0];
  const addressFormatted = formatAddress(firstResult);
  if (addressFormatted.address) {
    localStorage.setItem('address', JSON.stringify(addressFormatted));
  }
};

const saveCurrentPositionIP = async () => {
  const result = await getPositionData()
  if (result) {
    console.log("iplocation", result);
    localStorage.setItem('address', JSON.stringify(result));
  }
};

const getSavedPosition = () => {
  const address = localStorage.getItem('address');
  return address ? JSON.parse(address) : {};
};

const getAndSaveCurrentPosition = () => {
  navigator.geolocation?.getCurrentPosition(
    async (position) => {
      await saveCurrentPosition({ lat: position.coords.latitude, lng: position.coords.longitude });
    },
    async (errors) => {
      alert('Impossible de recuperer votre localisation');
      await saveCurrentPositionIP()
    }
  );
};

const loadMapScript = () => {
  const src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`;
  const id = 'google-maps';
  const position = document.querySelector('head');

  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);

  getAndSaveCurrentPosition();
};

const getPositionData = async () => {
  const response = await fetch('https://ipapi.co/json/');
  const data = await response.json();
  return formatIpAddress(data ?? {});
};


const formatIpAddress = (add) => {
  const address = `${add.city}, ${add.country_name}`;
  /* eslint-disable */
  const country = add.country_name ?? ''
  const country_code = add.country_code ?? '';
  const postal_code = add.postal ?? '';
  const route = '';
  const locality = add.city ?? '';
  const administrative_area_level_1 = '';
  const administrative_area_level_2 = '';
  const lat = add.latitude.toString() ?? '';
  const lon = add.longitude.toString() ?? '';
  return {address, country, country_code, postal_code, route, locality, administrative_area_level_1, administrative_area_level_2, lat, lon}
};

export { getPlaceDetails, getAndSaveCurrentPosition, getSavedPosition, loadMapScript };
