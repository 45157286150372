import { useState } from 'react';
// @mui
import { Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { matchIsValidTel, MuiTelInput } from 'mui-tel-input';
import { checkRecaptcha, sendCode } from "../../../services/login";

// ----------------------------------------------------------------------

export default function LoginForm({ next }) {

  const [isNotPhoneError, setIsNotPhoneError] = useState(true);
  const [phone, setPhone] = useState('');
  const [loading, setLoading] = useState(false);
  const handlePhoneChange = (newPhone) => {
    setIsNotPhoneError(matchIsValidTel(newPhone));
    setPhone(newPhone);
  };
  const handleClick = async () => {
    setLoading(true);
    checkRecaptcha();
    await sendCode(phone, next);
    setLoading(true);
    next(1);
  };

  return (
    <>
      <Stack spacing={1} mt={3}>
        <MuiTelInput
          preferredCountries={['FR', 'BE', 'CM']}
          langOfCountryName="fr"
          defaultCountry="CM"
          error={!isNotPhoneError}
          label="Votre numéro"
          value={phone}
          onChange={handlePhoneChange}
        />
        {!isNotPhoneError && (
          <Typography variant="caption" display="block" color="error" gutterBottom>
            Numéro invalide
          </Typography>
        )}
      </Stack>

      <LoadingButton loading={loading} sx={{ mt: 3 }} fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
        Suivant
      </LoadingButton>
    </>
  );
}
