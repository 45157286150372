import { createContext, useContext, useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { Box } from "@mui/material";
import { auth } from '../firebase';
import { getUser } from "../services/login";
import Loader from "../components/loader/Loader";

const FirebaseAuthContext = createContext(undefined);

const FirebaseAuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [userExist, setUserExist] = useState(false);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userData = await getUser(user.uid)
        setUserExist(Boolean(userData))
        setCurrentUser(userData);
        setPending(false);
      } else {
        setCurrentUser(null);
        setUserExist(true);
        setPending(false);
      }
    });
  }, []);

  const value = { user: currentUser, userExist, setUserExist, setCurrentUser };

  return pending ? (
    <Box minHeight="100vh" mt={40}>
      <Loader />
    </Box>
  ) : (
    <FirebaseAuthContext.Provider value={value}>{children}</FirebaseAuthContext.Provider>
  );
};

const useFirebaseAuth = () => {
  const context = useContext(FirebaseAuthContext);
  if (context) return context;
  return null;
};

export { useFirebaseAuth, FirebaseAuthProvider };
