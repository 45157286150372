// @mui
import PropTypes from 'prop-types';
import { Card, Typography, CardHeader, CardContent, Stack, Chip } from '@mui/material';
import { Timeline, TimelineDot, TimelineItem, TimelineContent, TimelineSeparator, TimelineConnector } from '@mui/lab';
import React from 'react';
import { fDateTimeD } from '../../utils/formatTime';
// utils
// ----------------------------------------------------------------------

HistoryTimeline.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  color: PropTypes.string,
  list: PropTypes.array.isRequired,
};

export default function HistoryTimeline({ title, subheader, list, ...other }) {
  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <CardContent
        sx={{
          '& .MuiTimelineItem-missingOppositeContent:before': {
            display: 'none',
          },
        }}
      >
        <Timeline>
          {list.map((item, index) => (
            <OrderHistoryItem key={item.id} item={item} isLast={index === list.length - 1} />
          ))}
        </Timeline>
      </CardContent>
    </Card>
  );
}

// ----------------------------------------------------------------------

OrderHistoryItem.propTypes = {
  isLast: PropTypes.bool,
  item: PropTypes.shape({
    time: PropTypes.instanceOf(Date),
    title: PropTypes.string,
    type: PropTypes.string,
  }),
};

function OrderHistoryItem({ item, color, isLast }) {
  const { type, title, time, status } = item;
  console.log(status);
  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot color={status.color} />
        {isLast ? null : <TimelineConnector />}
      </TimelineSeparator>

      <TimelineContent>
        <Stack direction="row" display="flex" justifyContent="space-between" justifyItems="center">
          <Stack direction="column" spacing={1} mb={2}>
            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
              {fDateTimeD(time)}
            </Typography>
            <Typography variant="subtitle2">{title}</Typography>
            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
              {type}
            </Typography>
          </Stack>
          <Chip variant="outlined" color={status.color} label={status.name} />
        </Stack>
      </TimelineContent>
    </TimelineItem>
  );
}
