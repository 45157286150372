import { collection, getDocs, query, where, orderBy } from 'firebase/firestore';
import { uniqBy } from 'lodash';
import { db } from '../firebase';
import { getCurrentUser } from './login';

const getAllColis = async (userId, phone) => {
  const [myColis, sendColis, recipientColis] = await Promise.all([
    await getMyColis({ field: 'userId', operator: '==', value: userId }),
    await getMyColis({ field: 'sender.phone', operator: '==', value: phone }),
    await getMyColis({ field: 'sender.phone', operator: '==', value: phone }),
  ]);

  return uniqBy([...myColis, ...sendColis, ...recipientColis], 'id');
};

const getMyColis = async ({ field, operator, value }) => {
  const user = getCurrentUser();
  const col = collection(db, 'enterprises', 'tgvairwabo', 'colis');
  const ref = query(col, where(field, operator, value), orderBy('createdAt', 'desc'));
  const querySnapshot = await getDocs(ref);
  return querySnapshot.docs.map((doc) => doc.data());
};

const getStatus = (status) => {
  const allStatus = [
    { name: 'Annuler', color: 'status0' },
    { name: 'Enregistré', color: 'status1' },
    { name: 'Expedié', color: 'status2' },
    { name: 'Receptionné', color: 'status3' },
    { name: 'Livre', color: 'status4' },
    { name: 'Scan', color: 'status5' },
    { name: 'Prise en charge', color: 'status6' },
    { name: 'Modifié', color: 'status7' },
    { name: 'EN TRANSITE', color: 'status8' },
  ];
  return allStatus[status];
};

const getStatusPayment = (status) => {
  const allStatus = [
    { name: 'Non payé', color: 'paid1' },
    { name: 'Payé', color: 'paid2' },
    { name: 'Remboursé', color: 'paid3' },
    { name: 'Avancé', color: 'paid4' },
  ];
  return allStatus[status - 1];
};

export { getAllColis, getStatus, getStatusPayment };
