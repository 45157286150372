import React, { useEffect, useState } from 'react';
import { Card, CardContent, CardHeader, Container, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { FormTextField } from '../forms';
import RequiredField from '../forms/RequiredField';
import GoogleAutocomplete2 from '../google-autocomplete/GoogleAutocomplete2';
import { getPlaceDetails } from '../../services/geocode';
import Iconify from '../iconify';
import { getUser, updateUser } from '../../services/login';
import { useFirebaseAuth } from '../../contexts/FirebaseAuthContext';

const Profile = () => {
  const { enqueueSnackbar } = useSnackbar();

  const { user, setCurrentUser } = useFirebaseAuth();

  const [address, setAddress] = useState(null);
  const [loading, setLoading] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onBlur',
  });

  useEffect(() => {
    getUser(user.id).then((user) => {
      reset({ name: user.name, surname: user.surname, email: user.email, address: user.address.inputAddress });
      setAddress(user.address);
    });
    return () => {};
  }, []);

  const onSubmitForm = async (e) => {
    setLoading(true);
    if (address) {
      const data = { ...e, address };
      try {
        await updateUser(data);
        setCurrentUser({ ...user, ...data });
        setLoading(false);
        enqueueSnackbar('Votre profil a été mis a jour', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } catch (e) {
        console.log({ e });
        setLoading(false);
        alert(JSON.stringify(e));
      }
    } else {
      alert('Adresse invalid');
      setLoading(false);
    }
  };

  return (
    <Container sx={{ mb: 10 }}>
      <Typography variant="h4" gutterBottom textAlign="center" sx={{ display: { xs: 'none' } }}>
        Votre profil
      </Typography>
      <Card
        sx={{
          mb: 3,
          maxWidth: 'md',
          margin: 'auto',
          display: 'flex',
          marginTop: 4,
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <CardHeader disableTypography />
        <CardContent>
          <form onSubmit={handleSubmit(onSubmitForm)}>
            <Stack spacing={2}>
              <FormTextField
                name="name"
                label="Votre nom"
                type="text"
                control={control}
                rules={{ required: true }}
                error={Boolean(errors.name)}
              />
              {errors.name && <RequiredField />}
              <FormTextField
                name="surname"
                label="Votre prénom"
                type="text"
                control={control}
                error={Boolean(errors.surname)}
                rules={{ required: true }}
              />
              {errors.surname && <RequiredField />}
              <FormTextField
                name="email"
                label="Votre e-mail"
                type="email"
                control={control}
                error={Boolean(errors.email)}
                rules={{
                  required: false,
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: 'Email invalide',
                  },
                }}
              />
              {errors.email && <RequiredField />}
              <GoogleAutocomplete2
                name="address"
                label="Votre adresse"
                control={control}
                onSelectPlace={async (place) => {
                  const address = await getPlaceDetails(place, '');
                  setAddress(address);
                }}
                error={Boolean(errors.address)}
              />
              {errors.address && <RequiredField />}
              <LoadingButton
                loading={loading}
                variant="contained"
                type="submit"
                startIcon={<Iconify icon="fluent:clipboard-text-edit-20-regular" />}
              >
                Modifier
              </LoadingButton>
            </Stack>
          </form>
        </CardContent>
      </Card>
    </Container>
  );
};

export default Profile;
