import React from 'react';
import { Container, Typography } from '@mui/material';
import Iconify from '../iconify';
import { StyledContent } from './styles';

const EmptyBox = ({title, icon}) => (
    <Container>
      <StyledContent sx={{ textAlign: 'center', alignItems: 'center' }}>
        <Typography variant="h3" paragraph>
          {title}
        </Typography>

        <Typography sx={{ color: 'text.secondary' }}>{}</Typography>

        <Iconify icon={icon} width={100} />
      </StyledContent>
    </Container>
  );

export default EmptyBox;
