// @mui
import { styled } from '@mui/material/styles';
// components
import { Helmet } from 'react-helmet-async';
import { Box } from '@mui/material';
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import TopHeader from '../../components/top-header';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
}));

// ----------------------------------------------------------------------

const PageLayout = forwardRef(({ children, title = '', ...other }, ref) => (
  <StyledRoot>
    <Helmet>
      <title>{`${title} | TGV'AIRWABO`}</title>
    </Helmet>
    <Main>
      <TopHeader />

      {children}
      <Box mt={5} />
    </Main>
  </StyledRoot>
));
PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

export default PageLayout;
